<template>
  <div
    v-if="+totalPrice.amount > 0"
    class="price-detail__promotion"
  >
    <span>{{ name }}</span>

    <template v-if="canShowDetail">
      <s-popover
        class="checkout-summary-promotion__popover"
        theme="light"
        placemen="bottom-end"
        :append-to-body="false"
        :show-close-icon="false"
        trigger="hover"
        :prop-style="propStyle"
        @opened="openPopover"
        @closed="closePopover"
      >
        <div class="checkout-summary-promotion__popover-content">
          <div class="checkout-summary-promotion__popover-content-body">
            <div class="checkout-summary-promotion__popover-content-detail">
              <span class="price-detail-left">{{ name }}</span>
              <span 
                class="price-detail-right price-detail-right__hover"
                @click="showDiscountDetails = !showDiscountDetails"
              >
                <div class="detail">
                  -{{ totalPrice.amountWithSymbol }}
                </div>
                <Icon
                  :name="showDiscountDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
                  :is-responsive-name="true"
                  size="12px"
                  color="#959595"
                />
              </span>
            </div>
            <DiscountList 
              v-show="showDiscountDetails"
              :language="language"
              :carts-list="checkout.results.carts.carts"
              :discount-list="promotionDiscountList"
              type="promotion"
            />
          </div>
        </div>

        <template #reference>
          <span class="price-detail-right price-detail-right__hover">
            <div class="detail">
              -{{ totalPrice.amountWithSymbol }}
            </div>
            <Icon
              :name="showPromotionDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
              :is-responsive-name="true"
              size="12px"
              color="#959595"
            />
          </span>
        </template>
      </s-popover>
    </template>
    <template v-else>
      <span class="price-detail-right">
        <em>
          -{{ totalPrice.amountWithSymbol }}
        </em>
      </span>
    </template>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

import DiscountList from '../discount_goods_info/DiscountList.vue'
import { Icon } from '@shein-aidc/icon-vue3'

const store = useStore()

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  totalPrice: {
    type: Object,
    default: () => {}
  },
  promotionDiscountList: {
    type: Array,
    default: () => []
  },
})

let showPromotionDetails = ref(false)
let showDiscountDetails = ref(true)

const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const GB_cssRight = computed(() => store.state.locals?.GB_cssRight)

const propStyle = computed(() => {
  return GB_cssRight.value ? {
    width: '360px',
    paddingLeft: '6px'
  } : {
    width: '360px',
    paddingRight: '6px'
  }
})

const canShowDetail = computed(() => {
  const detail = props.promotionDiscountList || []
  /**
   * 1、几种场景的触发均不展示promotion的明细项
   * 活动列表中价格/商品未获取到
   */
  const target = detail.find(_ => !_.discount_price?.amountWithSymbol || !_.cart_ids?.length)

  return !!detail.length && !target
})

const openPopover = () => {
  showPromotionDetails.value = true
}

const closePopover = () => {
  showPromotionDetails.value = false
  showDiscountDetails.value = true
}
</script>

<style lang="less" scoped>
.price-detail__promotion {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .price-detail-right {
    .detail {
      color: #FA6338;
      display: inline-block;
    }
    i {
      color: @sui_color_gray_light1;
    }
  }
  .price-detail-right__hover {
    cursor: pointer;
  }
}

.checkout-summary-promotion__popover-content {
  .checkout-summary-promotion__popover-content-body {
    max-height: 312px;
    overflow-y: scroll;
    .padding-r(10px);
  }
  .checkout-summary-promotion__popover-content-detail {
    font-size: 13px;
    padding-bottom: 12px;
    color: @sui_color_gray_dark1;
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
<style lang="less">
.checkout-summary-promotion__popover {
  .sui-popover__content {
    max-width: unset;
  }
}
</style>
