<template>
  <div 
    ref="togetherBuyContainer"
    :class="['together-buy', !isMultipleProducts && 'noPadding']"
  >
    <headerCon
      :together-buy="togetherBuy"
      :is-multiple-products="isMultipleProducts"
      @close-emit="closeEmit"
      @change-emit="changeEmit"
    />

    <template v-if="!isMultipleProducts">
      <!-- 单商品 -->
      <ClientOnly>
        <singleProduct 
          v-show="showSingleProduct" 
          ref="singleProduct"
          :language="language"
          :checkout="checkout"
          :recommend-products="togetherBuy.recommendProducts[0]"
          :together-buy="togetherBuy"
          @is-active-emit="upDateCurrentCartList"
        />
      </ClientOnly>

      <ProductListSkeleton
        v-show="showSingleSkeleton"
        type="goodsOne"
        :style="{'padding': '0 12px 10px 0', 'margin-top': '18px' }"
      />
    </template>
    <template v-else>
      <!-- 多商品 -->
      <ClientOnly>
        <multipleProducts
          v-show="showMultipleProduct"
          ref="multipleProducts"
          @is-active-emit="upDateCurrentCartList"
        />
      </ClientOnly>
      <placeholderItem
        v-show="showMultipleSkeleton"
        :num="6"
        :style="{
          'margin-top':'10px',
          'margin-bottom': '-15px',
          'min-height': '261px'
        }"
      />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import gsap from 'gsap'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter'
import { formatFn } from 'public/src/pages/checkout/hooks/useTogether.js'

import couponMixin from 'public/src/pages/checkout/mixins/couponMixin.js'

import headerCon from './components/headerCon.vue'
import singleProduct from './components/singleProduct.vue'
import placeholderItem from 'public/src/pages/components/ccc/base/ProductListHolder.vue'
import { SMessage } from '@shein-aidc/sui-message'
import ProductListSkeleton from 'public/src/pages/product_list_v2/components/ProductListSkeleton.vue'
import multipleProducts from './components/multipleProducts.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-3' })

export default {
  name: 'TogetherBuy',
  components: {
    headerCon,
    placeholderItem,
    singleProduct,
    ProductListSkeleton,
    multipleProducts
  },
  mixins: [couponMixin],
  data(){
    return {
      cartListNode: null,
      listNode: null,
      nodeWidth: 0,
      productTransparentObj: null,
      realQuickShip: null,
      isClient: false,
    }
  },
  computed: {
    ...mapState(['checkout', 'language', 'togetherBuy', 'status']),
    showSingleProduct() {
      return !!this.togetherBuy?.recommendProducts?.length && this.isClient
    },
    showSingleSkeleton() {
      return !this.showSingleProduct
    },
    showMultipleProduct() {
      return !!this.togetherBuy?.recommendProducts?.length && this.isClient
    },
    showMultipleSkeleton() {
      return !this.showMultipleProduct
    },
    // 是否多列商品
    isMultipleProducts() {
      return this.togetherBuy?.togetherBuyType == 2
    },
    currentProductRef() {
      return this.isMultipleProducts ? this.$refs?.multipleProducts?.$el : this.$refs?.singleProduct?.singleProductRef
    },
    newCardAbt() {
      const { newProductCard } = this.togetherBuy
      // 新商卡：随手购商卡开关开启 && 商卡开关开启
      return this.isMultipleProducts ? newProductCard : true
    },
    isSingleDisappear() {
      return this.togetherBuy.AddToOrderGoodsShow === 'disappear'
    }
  },
  mounted(){
    this.isClient = true
    window.TOGETHERBUY = this

    // 监听推荐数据更新事件
    window.appEventCenter.on('update-recommend-data', () => {
      this.changeEmit()
    })
  },
  methods: {
    ...mapMutations(['changeTogetherBuy']),
    ...mapActions(['updateCart']),

    closeEmit(){
      this.closeContent()
    },
    async changeEmit(){
      this.changeDataDynamic()
      // 随手购触发change 事件
      if(this.isMultipleProducts){
        this.$refs.multipleProducts.doRecommend(() => this.reverse())
      } else {
        await this.updateRecommendProducts()
      }
    },
    // 接口更新
    updateCheckoutData() {
      // 没地址情况下，不调依赖地址的接口
      if(this.status.hasAddress != 0){
        // 更新运输方式
        window.checkoutEventCenter.emit('update-shipping-method', { address: this.checkout?.default_address, modifyCart: true })
      }

      // update cart
      this.updateCart({
        noUpdataShipping: 1,
        fn: () => {
          // 没地址情况下，mall_caculate_info 从购物车取值
          if(this.status.hasAddress == 0){
            this.checkout.mall_caculate_info = this.checkout.results.carts.data
            this.checkout.mall_caculate_info.isSupportCod = 1
            this.checkout.mall_caculate_info.is_display = 1
          }
        }
      })
    },
    // 关闭动效
    closeContent() {
      let tl = gsap.timeline()
      tl.to(this.$refs?.togetherBuyContainer, {
        duration: 0.3,
        ease: 'ease.out',
        opacity: 0,
      })
      tl.to(this.$refs?.togetherBuyContainer, {
        duration: 0.3,
        ease: 'ease.in',
        height: 0,
        padding: 0,
        margin: 0,
        onComplete: () => {
          this.changeTogetherBuy({
            togetherBuyType: 0
          })
        }
      })
    },
    // 改变列表数据时透明化动效
    changeDataDynamic() {
      if (!this.productTransparentObj) {
        this.productTransparentObj = gsap.to(this.currentProductRef, {
          duration: 0.5,
          ease: 'ease.both',
          opacity: 0
        })
      } else {
        this.productTransparentObj.restart()
      }
    },
    // 更新好数据后恢复透明度
    reverse() {
      const singleOptions = {
        duration: 0.3,
        ease: 'ease.both',
        opacity: 1,
        width: '100%',
        scale: 1,
      }
      gsap.to(this.currentProductRef, !this.isMultipleProducts ? singleOptions : {
        duration: 0.3,
        ease: 'ease.both',
        opacity: 1
      })
    },
    // 动效
    reducingDynamic(obj) {
      let tl = gsap.timeline()

      tl.to(obj.el, {
        scale: 0,
        ease: 'ease.both',
        opacity: 0,
        duration: 0.5,
        width: 0,
        onComplete: () => {
          if(!this.isMultipleProducts){
            if(!this.isSingleDisappear){
              // 更新数据动效，需要在购物车数据更新后
              this.updateRecommendProducts()
            } else {
              this.closeEmit()
            }
            return 
          }
          obj?.goods_id && window.appEventCenter.emit('togerher-add-quickView', {
            goods_id: obj.goods_id,
            cb: (recommendData) => {
              if(!recommendData.length){ return this.changeEmit() }
            }
          })
        }
      })
    },
    async scrollToMall() {
      return new Promise(resolve =>{
        try{
          setTimeout(()=>{
            this.cartListNode = document.getElementsByClassName(`_current_dom_together_buy_mall${this.quickViewCartDataMallCode}_quick${this.realQuickShip}`)?.[0]
            this.listNode = this.cartListNode?.getElementsByClassName('checkout-cart__list-body')

            const nodeRect = this.cartListNode.getBoundingClientRect()

            const HALF_FACTOR = 0.5

            // 计算元素的中心位置
            const center = nodeRect.top + nodeRect.height * HALF_FACTOR

            if (center < 0) {
              window.scrollBy({ top: -(Math.abs(nodeRect.top) + 120), behavior: 'smooth' })
            }

            if (nodeRect.bottom > window.innerHeight) {
              window.scrollBy({ top: nodeRect.bottom - window.innerHeight + 100, behavior: 'smooth' })
            }

            resolve(true)
          })
        }catch(err){
          console.log(err)
        }
      })
    },
    // 更新数据
    async upDateCurrentCartList(obj){
      let { info = {}, cart } = this.togetherBuy.quickViewCartData
      cart = info?.cart || cart || {}
      info = info?.info || info || {}
      const buildCars = info?.cart || info?.carts  || []
      const id = cart.id
      const findData = buildCars.find(item => item.id == id)
      this.quickViewCartDataMallCode = findData.mall_code
      this.realQuickShip = findData.real_quick_ship
      // 保存加车商品的id，用于排序在最前面
      this.changeTogetherBuy({
        addCartProducs: this.togetherBuy.addCartProducs.includes(id) ? [...this.togetherBuy.addCartProducs] : [id, ...this.togetherBuy.addCartProducs],
        quickViewCartDataMallCode: this.quickViewCartDataMallCode
      })
      await this.scrollToMall()    
      await this.updateCheckoutData()

      this.reducingDynamic(obj)
    },

    // 更新随手购数据
    async updateRecommendProducts() {
      try{
        /** 无法再直接改 node层 mock abtControl 显示单商品/多商品，直接通过abt取的值，要mock，得直接改abt  */
        const params = formatFn({ abt: this.checkout.checkoutBFFAbtInfo, carts: this.checkout?.results?.carts?.carts, exposedGoodsId: this.togetherBuy.exposedGoodsId, countryId: this.checkout?.default_address?.countryId })
        this.changeTogetherBuy({
          recommendProducts: [],
        })  
        let result = await schttp({
          method: 'POST',
          url: '/api/checkout/recommendProducts/get',
          data: params,
        })
        if (result.code == 0 && result.info.products?.length) {
          this.changeTogetherBuy({
            recommendProducts: result.info.products,
            promotionInfoFromServer: result.info.promotionInfoFromServer
          })  
          window?.appEventCenter.emit('together-buy-updated', result.info.products[0]) 
        } else if (result.code == -2){
        // 可推荐数量没有达到门槛
        // 单个商品，根据abt判断是不展示推荐模块，还是提示文案
        // 多个商品，直接不展示推荐模块
          if ((this.togetherBuy?.togetherBuyType == 1 && this.isSingleDisappear) || this.togetherBuy?.togetherBuyType == 2) {
          // 模块消失
            this.closeContent()
          } else {
            SMessage({
              type: 'error',
              message: this.language.SHEIN_KEY_PC_28078
            })
          }
        } else {
        // 接口请求失败
          SMessage({
            type: 'error',
            message: this.language.SHEIN_KEY_PC_28079
          })
        }

        this.reverse()
      }catch(err){
        console.log(err)
      }

    },
  }
}
</script>
<style lang="less" scoped>
.together-buy {
    margin: 10px 0;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 16px;
    box-sizing: border-box;
}
.noPadding{
  padding: 0;
}
</style>
