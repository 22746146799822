import { ref, shallowRef, set, nextTick, computed } from 'vue'
import MD5 from 'md5'
import schttp from 'public/src/services/schttp'
import apiReport from 'public/src/pages/common/apiReport'
import { mergeQueryString } from '@shein/common-function'
// import { replacePaySuccessReferer } from '@/public/src/pages/common/handlePay/tools.js'
import { postTokenUnPayComplete } from '@/public/src/pages/common/oneClickPay/fetcher.js'
import kafkaReport from '@/public/src/pages/common/handlePay/kafkaReport.js'
import { debuggerLog, getDeviceInfo, getProductType, getCheckoutAgainUrl, makeForm, commonSendDdc } from '../utils'
import useGetPubKey from './useGetPubKey'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { useAlipayCardThreeDs } from 'public/src/pages/common/handlePay/hooks/useAlipayCardThreeDs.js'
import { encryptOest } from '@shein-aidc/basis-oesthandle'

const noop = function () {}

export default ({
  scene = '',
  challengeModalOptions = { adyenChallengeShow: false, wpChallengeShow: false }
} = {}) => {
  console.log('useTokenPay', scene)
  // let initPciPromise = null
  const tokenCvv = ref('')
  const unifiedPayChallengeInfo = ref({
    gatewayPayNo: '',
    paymentCode: '',
    independThreedTraceID: ''
  })
  const ddcInfo = ref({
    formActionUrl: '',
    jwt: ''
  })
  const tokenList = ref([])
  const selectedTokenPayInfo = ref({
    id: '',
    card_bin: '',
    last_four_no: ''
  })
  const tokenPayParams = ref({
    billno: '',
    payment_method: '',
    id: '',
    card_bin: '',
    last_four_no: '',
    countryCode: ''
  })

  const tokenPayConfig = shallowRef({
    checkoutType: '',
    toggleLoadingStatus: () => {},
    onPayComplete: noop
    // wpChallengeModalClassName: 'token-pay-challenge__iframe',
  })
  const necessaryOrderInfo = ref({
    billno: '',
    sub_billnos: [],
    totalPrice: {}
  })

  const adyenInstance = shallowRef(null)
  const ppCardInstance = shallowRef(null)

  const isShowCvvDialog = ref(false)

  const { getPubKey } = useGetPubKey()

  const initTokenSuccess = computed(() => {
    return adyenInstance.value || ppCardInstance.value
  })

  function initTokenPayConfig ({
    checkoutType = '',
    toggleLoadingStatus
  } = {}) {
    tokenPayConfig.value.checkoutType = checkoutType || ''
    tokenPayConfig.value.toggleLoadingStatus = toggleLoadingStatus || function () {}
    import('../channel/adyen.js').then(async ({ default: Adyen3ds }) => {
      const adyenKeyInfo = await schttp({
        url: '/ltspc/api/pay/adyenKey/get'
      })
      adyenInstance.value = new Adyen3ds({
        completeHandle: _handleThreeDSComplete,
        errorHandle: _handleThreeDSError,
        originKey: adyenKeyInfo?.info?.key || ''
      })
    })
    import('../channel/pp-card.js').then(({ default: PayPalCard }) => {
      ppCardInstance.value = new PayPalCard({
        completeHandle: _handleThreeDSComplete,
        errorHandle: _handleThreeDSError
      })
    })
  }

  function setTokenPayInfo (params = {}) {
    const { tokenList: list = [], selectedTokenPayInfo: tokenInfo = {}, ddcInfo: info, necessaryOrderInfo: order = {} } = params || {}
    tokenList.value = list || []
    selectedTokenPayInfo.value = tokenInfo || {}
    ddcInfo.value.jwt = info?.jwt || ''
    ddcInfo.value.formActionUrl = info?.formActionUrl || ''
    necessaryOrderInfo.value = order || {}
  }

  function setTokenPayCvv (cvv = '') {
    tokenCvv.value = cvv || ''
  }

  async function handleTokenPay ({
    params = {},
    onPayComplete = noop,
    unHandleResultCb
  } = {}) {
    tokenPayConfig.value.onPayComplete = onPayComplete

    const {
      billno = '',
      payment_method = '',
      id,
      card_bin,
      last_four_no,
      countryCode = '',
      sessionId = '', // ddc sessionId
      installments, // 分期数
      vipOrderSource
    } = params

    for (const key in params) {
      if (set) {
        set(tokenPayParams.value, key, params[key])
      } else {
        tokenPayParams.value[key] = params[key]
      }
    }

    const productType = getProductType({ type: tokenPayConfig.value.checkoutType })

    debuggerLog('handleTokenPay--orgs-----', { params })

    const data = {
      billno,
      tokenId: id,
      publicKeyId: '',
      cardBin: card_bin || '',
      cardLastFour: last_four_no,
      sessionId: sessionId || '',
      paymentCode: payment_method,
      deviceFingerId: window.GB_cybersource_uuid || '',
      forterDeviceFingerprintID: window.forterDeviceId || '',
      riskifiedDeviceFingerprintID: window.riskifiedDeviceId || '',
      challengeWindowSize: 'fullPage',
      vipOrderSource,
    }

    if (installments) {
      data.installments = installments
    }
    
    const { encryptValid, publicKeyObj, encryptObj } = await getPubKey({ countryCode })
    data.publicKeyId = publicKeyObj?.pub_id

    if (data.forterDeviceFingerprintID) {
      kafkaReport?.addTokenPayReport?.('forterDeviceFingerprintID', '风控数据采集正常')
    }
    kafkaReport?.addTokenPayReport?.('publicKeyObjKey', data.publicKeyId ? '加载加解密公钥正常' : '加解密公钥获取异常')
    if (adyenInstance.value || ppCardInstance.value) {
      kafkaReport?.addTokenPayReport?.('channelSdkOnLoad', '加载渠道js运行正常')
    }
    kafkaReport?.addTokenPayReport?.('encryptValid', encryptValid ? '卡号加密成功' : '卡号加密失败')

    if (tokenCvv.value) {
      if (!encryptValid) {
        console.error('encryptValid is false')
        apiReport?.report?.({
          apiPath: '/pubkey/',
          payMethod: payment_method,
          siteuid: gbCommonInfo?.SiteUID,
          status_code: 0,
          response: '',
          billno,
        })
      }
      data.cvvHash = encryptObj?.encrypt?.(tokenCvv.value)
      data.cvvCheck = ''
      if (MD5) {
        data.cvvCheck = MD5(publicKeyObj?.pub_id + data.cvvHash + publicKeyObj?.pub_id)
      }
    }

    if(window.forterSDKLoaded && !window.forterDeviceId){ //js初始化成功，且在发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'forter/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'js sdk init success but forter deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }
    if(productType != 'gift_card' && !window.GB_cybersource_uuid) { // 非礼品卡，发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'cybs/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'cybs deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }
    if(window.isRiskifiedLoaded && !window.riskifiedDeviceId) { // 发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'riskified/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'risk deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }

    // 同盾指纹
    if (window._fmOpt?.__blackbox) {
      data.blackbox = window._fmOpt.__blackbox
    } else {
      // 未获取到同盾指纹上报
      apiReport.report({
        apiPath: 'tongdun/before-payment/error',
        sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
        billno: billno,
        paymentMethod: payment_method
      })
    }

    debuggerLog('handleTokenPay--data-----', data)

    postTokenUnifiedPay(data)
      .then(res => {
        // res = ppcJsonMock
        const { status } = unHandleResult(res, unHandleResultCb) || {}
        debuggerLog('unHandleResult--status-----', status)
        debuggerLog('postTokenUnifiedPay--result-----', res)
        if (status) {
          tokenPayConfig.value.onPayComplete?.({ status, result: res })
        }
        // const unHandleResult(res) || {}
        // debuggerLog('unHandleResult--status-----', status)
        // if (status === 'success') {
        //   this.resultHandle(res)
        // } else if (status === 'fail') {
        //   this.ocpPayFailHandle(res)
        // }
      }).catch(err => {
        tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err })
        kafkaReport?.sendData?.({ resStatus: err?.status || '-1' })
      }).finally(() => {
        setTokenPayCvv('') // 清空cvv
      })
  }

  async function normalTokenPayResultHandle ({ status, res } = {}) {
    // const { host, langPath } = gbCommonInfo
    const productType = getProductType({ type: tokenPayConfig.value.checkoutType })
    debuggerLog('normalTokenPayResultHandle--', { productType, status, res })
    // const isGiftCard = productType === 'gift_card'
    // if (status === 'success') {
    //   !isGiftCard && replacePaySuccessReferer()
    //   window.location.href = `${host}${langPath}${isGiftCard ? '/giftcard' : ''}/pay/result/success?billno=${tokenPayParams.value?.billno}`
    // } else if (status === 'pending') {
    //   window.location.href = `${host}${langPath}${isGiftCard ? '/giftcard' : ''}/pay/result/pending?billno=${tokenPayParams.value?.billno}`
    // } else if (status === 'fail') {
    //   window.location.href = `${host}${langPath}${isGiftCard ? '/giftcard' : ''}/pay/result/fail?billno=${tokenPayParams.value?.billno}&is_guide=1`
    // }
    tokenPayConfig.value?.toggleLoadingStatus?.(false)
    return { status, res }
  }

  function unFilterTokenData(data = {}, options = {}) {
    const { checkoutType = '' } = options || {}
    const { langPath, host } = gbCommonInfo
  
    const productType = getProductType({ type: checkoutType })
    const checkoutAgainUrlMap = getCheckoutAgainUrl({ billno: data.billno, checkoutType })
  
    let cancelUrl = checkoutAgainUrlMap['pc']
    let callbackUrl = `${host}${langPath}/ltspc/pay/result/unifiedCb?bill_no=${data.billno}`
    if (productType === 'one_click_pay') {
      callbackUrl = mergeQueryString({
        url: callbackUrl,
        mergeObj: {
          isOcp: 1,
          ocpCbUrl: encodeURIComponent(location.href)
        }
      })
      cancelUrl = mergeQueryString({
        url: callbackUrl,
        mergeObj: {
          ocpCancel: 1
        }
      })
    } else {
      callbackUrl = mergeQueryString({
        url: callbackUrl,
        mergeObj: {
          isFrontToken: 1
        }
      })
    }
    const failureUrl = callbackUrl
    const pendingUrl = callbackUrl
  
    const formData = {
      billno: data.billno || '',
      publicKeyId: data.publicKeyId || '',
      sessionId: data.sessionId || '',
      tokenId: data.tokenId || '',
      cardBin: data.cardBin || '',
      cardLastFour: data.cardLastFour || '',
      deviceFingerId: data.deviceFingerId || '',
      challengeWindowSize: data.challengeWindowSize || '',
      forterDeviceFingerprintID: data.forterDeviceFingerprintID || '',
      riskifiedDeviceFingerprintID: data.riskifiedDeviceFingerprintID || '',
      paymentCode: data.paymentCode || '',
      cvvHash: data.cvvHash || '',
      cvvCheck: data.cvvCheck || '',
      GB_cybs_loaded: window.GB_cybs_loaded ? 1 : 0,
      callbackUrl,
      cancelUrl,
      pendingUrl,
      failureUrl,
      device_language: navigator.language || navigator.browserLanguage || '',
      neurPayId: data.billno + '_' + Math.floor(Math.random() * 99999),
      neurStep: 1,
      installments: data.installments || 0,
      // 自定义参数
      checkoutScene: 'front_token',
      vipOrderSource: data.vipOrderSource || 0,
      ...getDeviceInfo()
    }

    return formData
  }

  async function postTokenUnifiedPay(data) {
    const checkoutType = tokenPayConfig.value?.checkoutType
    const formData = unFilterTokenData(data, { checkoutType })
    const url = '/ltspc/pay/unPay/unifiedPay'

    kafkaReport?.updateKafkaData?.({
      neurStep: formData.neurStep,
      neurPayId: formData.neurPayId,
      startTime: +new Date(),
      callUrl: url,
      billno: formData.billno,
      paymentMethod: formData.paymentCode,
    })
    return schttp({
      method: 'post',
      url,
      data: formData
    })
  }

  function _handleMessageChange (event) {
    const types = ['challenge', 'normal', 'ocpResult']
    debuggerLog('event---message----', event.data)
    if (event.origin == location.origin && event.data) {
      try {
        var data =
            typeof event.data === 'string'
              ? JSON.parse(event.data)
              : event.data || {}
        if (
          data !== undefined &&
            data.app === 'shein' &&
            types.includes(data.type)
        ) {
          const result = typeof data.result === 'string' ? JSON.parse(data.result) : data.result || {}
          debuggerLog('message---result---', result)
          challengeModalOptions.wpChallengeShow = false
          const { status } = unHandleResult(result) || {}
          debuggerLog('unHandleResult--result--status-----', status)
          if (status) {
            tokenPayConfig.value.onPayComplete?.({ status, result })
            window.removeEventListener('message', _handleMessageChange, false)
          }
        }
      } catch (e) {
        debuggerLog('postMessage error: ' + e)
      }
    }
  }

  function unHandleResult(res, unHandleResultCb) {
    debuggerLog('unHandleResult--', res)
    unHandleResultCb && unHandleResultCb()
    // challengeModalOptions.wpChallengeShow = false

    kafkaReport?.sendData?.({ res, resStatus: 200 })

    //只处理公用action
    if (res && res.code == 0 && res.info) {
      const {
        action,
        paramList,
        actionUrl,
        result,
        gatewayPayNo,
        paymentCode,
        show_error_msg,
        isIndepenDsThreed,
        independThreedTraceID = '',
        independThreeDsChannel,
        pending
      } = res.info
      
      // 先清空，避免独立3ds失败兜底切换成渠道3ds时传入错误的值
      unifiedPayChallengeInfo.value.independThreedTraceID = ''

      //跳转支付
      if (action == 'redirect') {
        window.location.href = actionUrl
        return
      } else if (action == 'render' && paramList) {
        // 触发独立3ds挑战
        unifiedPayChallengeInfo.value.independThreedTraceID = isIndepenDsThreed == 1 && independThreedTraceID ? independThreedTraceID : ''

        // 通用ddc收集
        if (
          independThreeDsChannel === 'cybersource' &&
            isIndepenDsThreed === 1 &&
            paramList.challengeFlag != '1'
        ) {
          unifiedPayChallengeInfo.value.gatewayPayNo = gatewayPayNo
          unifiedPayChallengeInfo.value.paymentCode = paymentCode
          const { redirectUrl, method, parameters = '{}' } = paramList
          commonSendDdc({
            action: redirectUrl,
            method,
            params: JSON.parse(parameters),
            cb: () => _handleThreeDSComplete({ paramList }),
          })
          return true
        }
        
        // cybersource 3ds挑战
        if (
          independThreeDsChannel === 'cybersource' &&
          paramList.challengeFlag == '1'
        ) {
          const cybr3dsForm = document.createElement('form')
          cybr3dsForm.style.display = 'none'
          cybr3dsForm.action = paramList.redirectUrl
          cybr3dsForm.method = paramList.method
          let inputsDomStr = ''
          if (paramList.parameters) {
            const parameters = JSON.parse(paramList.parameters)
            Object.keys(parameters).forEach(key => {
              inputsDomStr += `<input type="hidden" name="${key}" value="${parameters[key]}"/>`
            })
          }
          cybr3dsForm.innerHTML = inputsDomStr
          document.body.appendChild(cybr3dsForm)
          cybr3dsForm.submit()
          return true
        }
        
        if (paymentCode == 'worldpay-cardjs3ds') {
          const { jwt, md } = paramList
          if (jwt && md) {
            paramList.JWT = jwt
            paramList.MD = md
            delete paramList.jwt
            delete paramList.md
            challengeModalOptions.wpChallengeShow = true
            window.addEventListener('message', _handleMessageChange, false)
            _showWpChallenge(actionUrl, paramList)
            return
          }
        }

        if (paymentCode == 'alipay-card3ds') {
          unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
          unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
          // 支付宝3ds挑战
          useAlipayCardThreeDs({
            paramList,
            completeHandle: _handleThreeDSComplete
          })
          return true
        }

        if (isIndepenDsThreed == 1 && paramList.TermUrl) {
          // 触发独立3ds 1.0 挑战
          let termurl = paramList.TermUrl
          paramList.TermUrl =
            termurl.indexOf('?') > -1
              ? `${termurl}&independThreedTraceID=${independThreedTraceID}`
              : `${termurl}?independThreedTraceID=${independThreedTraceID}`
        }

        // window.verifyResultBiSend && verifyResultBiSend(true)
        makeForm(paramList, actionUrl)
        return
      } else if (action == 'challenge' && paramList) {

        if (res.info?.neurData?.neurStep) {
          kafkaReport?.updateKafkaData?.({ neurStep: +res.info.neurData.neurStep })
        }
        //挑战流程
        if (action == 'challenge') {
          // window.ocpEventCenter?.$emit?.('show-process-toast', false)
          if (
            paramList.fingerprintToken ||
            paramList?.['threeds2.fingerprintToken']
          ) {
            unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            // window.ocpEventCenter?.$emit?.('show-global-loading', true)
            challengeModalOptions.adyenChallengeShow = true
            adyenInstance.value?.init3DSIS?.(
              paramList.fingerprintToken ||
                paramList?.['threeds2.fingerprintToken']
            )
          } else if (
            paramList.challengeToken ||
            paramList?.['threeds2.challengeToken']
          ) {
            debuggerLog('challengeToken', paramList.challengeToken)
            unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            challengeModalOptions.adyenChallengeShow = true
            adyenInstance.value?.initChallenge?.(
              paramList.challengeToken ||
                paramList?.['threeds2.challengeToken']
            )
          } else if (res?.info.paymentCode == 'PayPal-card') {
            // payEventCenter.$emit('show-global-loading', true)
            // this.gatewayPayNo = paramList.gatewayPayNo
            unifiedPayChallengeInfo.value.gatewayPayNo = paramList.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            // this.paymentCode = info.paymentCode
            ppCardInstance.value?.initPaypalDropIn?.({
              paramList,
              totalPriceAmount: necessaryOrderInfo.value?.totalPrice?.amount || '',
              email: UserInfoManager.getSync({ key: 'userinfo_email', actionType: 'hooks/useTokenPay' }) || ''
            })
          }
          return
        }
      } else if (action == 'direct' && result == 1) {
        //直接返回支付结果
        return {
          status: 'success',
          result: res
        }
      } else if (action == 'direct' && result == 0 && pending == 1) {
        // window.verifyResultBiSend && verifyResultBiSend(true)
        // window.location.href = checkPending({ billno })
        return {
          status: 'pending',
          result: res
        }
      } else if (action == 'direct' && result == 0 && show_error_msg) {
        //处理报错文案，如果有客服链接，将{0}替换成langPath以适应不同站点
        const reg = /\{0\}\/robot/
        if (reg.test(show_error_msg)) {
          res.info.show_error_msg = show_error_msg.replace(
            reg,
            gbCommonInfo?.langPath + '/robot'
          )
        }
      }
    }
    return {
      status: 'fail',
      result: res
    }
  }

  function _showWpChallenge(actionUrl, paramList) {
    nextTick(() => {
      let iframe = document.createElement('iframe')
      iframe.setAttribute('width', '400')
      iframe.setAttribute('height', '250')
      iframe.setAttribute('name', 'challenge')

      let iframeContainer = document.querySelector(
        '.token-pay-challenge__iframe'
      )
      if (iframeContainer) {
        iframeContainer.innerHTML = ''
        iframeContainer.appendChild(iframe)
      }

      let form = document.createElement('form')
      form.setAttribute('id', 'challengeForm')
      form.setAttribute('name', 'challengeForm')
      form.setAttribute('method', 'POST')
      form.setAttribute('target', 'challenge')
      form.setAttribute('action', actionUrl)

      let jwtInput = document.createElement('input')
      jwtInput.setAttribute('type', 'hidden')
      jwtInput.setAttribute('name', 'JWT')
      jwtInput.setAttribute('value', paramList.JWT)
      form.appendChild(jwtInput)

      let mdInput = document.createElement('input')
      mdInput.setAttribute('type', 'hidden')
      mdInput.setAttribute('name', 'MD')
      mdInput.setAttribute('value', paramList.MD)
      form.appendChild(mdInput)

      let submitInput = document.createElement('input')
      submitInput.setAttribute('type', 'submit')
      submitInput.setAttribute('value', 'Continue')
      submitInput.style.display = 'none'
      form.appendChild(submitInput)

      document.body.appendChild(form)

      document.challengeForm?.submit?.()
    })
  }

  function _handleThreeDSComplete({ step, paramList, ...specialData } = {}) {
    debuggerLog('handleThreeDSComplete--', { step, paramList, ...specialData })
    if (step === 'ChallengeShopper') {
      challengeModalOptions.adyenChallengeShow = false
      tokenPayConfig.value?.toggleLoadingStatus?.(true)
    }

    const data = {
      billno: tokenPayParams.value.billno,
      gatewayPayNo: unifiedPayChallengeInfo.value.gatewayPayNo,
      paymentCode: unifiedPayChallengeInfo.value.paymentCode,
      independThreedTraceID: unifiedPayChallengeInfo.value.independThreedTraceID || '',
      verificationStep: step || '',
      verificationResult: specialData?.verificationResult || '',
      independThreedsVerifyInfo: specialData?.independThreedsVerifyInfo || '',
      threeDVerifyResult: specialData?.threeDVerifyResult || '',
      neurPayId: kafkaReport.kafkaInfo?.neurPayId,
      neurStep: kafkaReport.kafkaInfo?.neurStep + 1
    }

    // 整个paramList传给后端，后端自己挑选需要的参数
    if(paramList){
      data.paramList = typeof paramList === 'string' ? paramList : JSON.stringify(paramList)
    }

    kafkaReport?.updateKafkaData?.({
      neurStep: data.neurStep,
      neurPayId: data.neurPayId,
      startTime: +new Date(),
      callUrl: '/ltspc/pay/unPay/complete'
    })

    postTokenUnPayComplete(data)
      .then(result => {
        // result = ppcrJsonMock
        debuggerLog('postTokenUnPayComplete--result-----', result)
        const { status } = unHandleResult(result) || {}
        debuggerLog('unHandleResult--result--status-----', status)
        if (status) {
          tokenPayConfig.value.onPayComplete?.({ status, result })
        }
        // if (status) {
        //   return { status, result }
        // }
        // if (tokenPayConfig.value.checkoutType === 'normal') {
        //   normalTokenPayResultHandle({ status, res: result })
        // }
      }).catch(err => {
        tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err })
        kafkaReport?.sendData?.({ resStatus: err?.status || '-1' })
      })
      // .finally(() => {
      //   this.updateLoadingStatus({ loading: false })
      //   window.ocpEventCenter?.$emit?.('show-process-toast', false)
      // })
  }

  function _handleThreeDSError(err) {
    debuggerLog('handleThreeDSError--', err)
    challengeModalOptions.adyenChallengeShow = false
    // this.updateLoadingStatus({ loading: false })
    // this.ocpPayFailHandle({})
    // _handleError(err)
    tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err || {} })
    return err
  }

  // function _handleError (err) {
  //   if (tokenPayConfig.value.checkoutType === 'normal') {
  //     normalTokenPayResultHandle({ status: 'fail', res: err || {} })
  //   }
  // }

  async function initTransformPci ({
    SiteUID = '',
    lang = '',
    AppCurrency = '',
    siteFrom = 'pc'
  } = {}) {
    const payConfig = await schttp({
      url: '/api/ocp/payConfig/get'
    }).catch(() => ({ code: '-1', info: {}, msg: 'fail' }))

    if (payConfig?.code != 0) {
      return { code: payConfig?.code, msg: 'need login' }
    }

    const oest = UserInfoManager.get({ key: 'oest' })
    const enOest = encryptOest(oest)

    return schttp({
      url: '/ltspc/transformer/init',
      method: 'POST',
      data: {
        SiteUID,
        lang,
        AppCurrency: AppCurrency,
        TokenAes: payConfig?.info?.aesToken,
        _csrf: payConfig?.info?.csrfToken,
        siteFrom,
        enOest
      }
    })
  }

  return {
    tokenCvv,
    ddcInfo,
    selectedTokenPayInfo,
    initTokenPayConfig,
    setTokenPayInfo,
    handleTokenPay,
    normalTokenPayResultHandle,
    initTransformPci,
    setTokenPayCvv,
    isShowCvvDialog,
    initTokenSuccess
  }
}
