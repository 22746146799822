<template>
  <div class="new-giftcard">
    <div class="new-giftcard__header">
      <div class="new-giftcard__title">
        {{ language.SHEIN_KEY_PC_26031 }}
      </div>
      <SPopover 
        :model-value="isShowPopover"
        :content="getFirstEntryTips"
        :show-close-icon="true"
        :trigger="isShowPopover ? 'hover' : 'unset'"
      >
        <template #reference>
          <div
            class="new-giftcard__list"
            :style="{
              color: !!disableCreate ? '#2D68A8': '#909090'
            }"
            @click="handleOpen"
          >
            {{ language.SHEIN_KEY_PC_33565 }}
            <div
              v-if="newGiftcard?.red_dot == 1 && isShowRedDot"
              class="red-dot"
            ></div>
          </div>
        </template>  
      </SPopover>
    </div>
    <BBindGiftCard 
      ref="bsBindGiftCardRef"
      class="new-giftcard__bind"
      :is-form-inline="true"
      :is-show-switch="isShowSwitch"
      :is-show-step-text="false"
      :is-need-drawer="false"
      :gift-card-list="giftCardList"
      :visible="true"
      :is-disable="!disableCreate"
      :language="language"
      :error-tip="bindCardErrorTips"
      sence="checkout"
      link-btn-txt=""
      @handle-bind-card="(res) => onBindCard(res, false)"
      @bind-card-cb="(res) => onBindCard(res, false)"
    />
    <div v-if="selectedItemCalculate">
      <div class="new-giftcard__usetext">
        {{ getUseSuccessTips }}
      </div>

      <div
        class="new-giftcard__use"
      >
        <div class="discount">
          -{{ getDiscountPrice }}
        </div>
        <div class="sn">
          {{ selectedItemCalculate.card_no_tip }}
        </div>
        <div
          class="close"
          @click="() => onConfirm()"
        ></div>
        <Icon
          class="close-icon"
          name="sui_icon_closed_12px_1"
          size="9px"
          color="#fff"
          @click="() => onConfirm()"
        />
      </div>
      <div class="new-giftcard__balance">
        {{ getBalancePrice }}
      </div>
      <div
        v-if="giftCardLimitGoods.length"
        class="new-giftcard__limit"
      >
        <span
          class="reason-more"
          @click="viewLimitReason"
        >
          {{ language.SHEIN_KEY_PC_26079 }}
        </span>
      </div>
    </div>
    <BGiftCard
      v-if="apiCardsMap && visible"
      ref="giftCardRef"
      :extra-data="{
        selectedItem: selectedItemDrawer,
        isShowSwitch: isShowSwitch
      }"
      :drawer-props="{
        visible: visible,
      }"
      scene="checkout"
      :card-data="getCardsMap"
      @custom-event="onCustomEvent"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { BGiftCard } from '@shein-aidc/bs-gift-card-laptop'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import verifyManager from '@shein-risk/si_verify'
import { InitFtokenPromise } from 'public/src/pages/common/ftoken/index'
import BBindGiftCard from '@shein-aidc/bs-bind-gift-card-laptop'
import { template, cloneDeep } from '@shein/common-function'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { SMessage } from '@shein-aidc/sui-message'
import { Icon } from '@shein-aidc/icon-vue3'
import { useMapState, useMapActions, useMapMutation } from 'public/src/pages/store_pages/store/mapHook'

import { handleLimitGoods } from '../../config/tools'

const { checkout, language, caculateData, defaultShipping, storeData, giftCardLimitGoods } = useMapState(['checkout', 'language', 'caculateData', 'defaultShipping', 'storeData', 'giftCardLimitGoods'])
const { getNewGiftCardInfo } = useMapActions(['getNewGiftCardInfo'])
const  { changeParamsStatus, assignState } = useMapMutation(['changeParamsStatus', 'assignState'])

const visible = ref(false)
const apiCardsMap = ref(null)
const bsBindGiftCardRef = ref(null)
const selectedItemDrawer = ref(null) // 用于非抽屉场景控制抽屉中的选中态
const selectedItemCalculate = ref(null) // 用于记录非抽屉场景计价结果
const openPopover = ref(false)
const bindCardErrorTips = ref('')
const cardCache = ref([])
const giftCardRef = ref(null)
const isShowRedDot = ref(true)

const getDiscountPrice = computed(() => checkout.value?.mall_caculate_info?.giftcard?.giftCardUsedPrice?.amountWithSymbol || '')
const isShowPopover = computed(() => +newGiftcard.value?.guide_tip?.replace(/\D/g, '') > 0 && openPopover.value)
const newGiftcard = computed(() => checkout.value.newGiftcard)
const isShowSwitch = computed(() => +newGiftcard.value?.gift_card_abt === 2)
const getBalancePrice = computed(() => template(selectedItemCalculate.value?.balance?.amountWithSymbol, language.value?.SHEIN_KEY_PC_33252))
const getUseSuccessTips = computed(() => template(newGiftcard.value?.have_available_card, language.value?.SHEIN_KEY_PC_33564))
const getFirstEntryTips = computed(() => template(newGiftcard.value?.guide_tip?.replace(/\D/g, ''), language.value?.SHEIN_KEY_PC_33563))
const disableCreate = computed(() => checkout.value?.default_address?.addressId || (TRANSPORT_TYPE_SHOP.includes(defaultShipping?.value?.[0]?.shipping_method?.transport_type) && (!storeData.value || storeData.value?.status != 1)))
const giftCardList = computed(() => Object.values(getCardsMap.value).reduce((sum, item) => sum.concat(item), []))

const getCardsMap = computed(() =>  ({
  available_card_list: [].concat(cardCache.value, apiCardsMap.value?.available_card_list).filter(Boolean),
  unavailable_card_list: [].concat(apiCardsMap.value?.unavailable_card_list).filter(Boolean),
}))

const getCardListData = async() => {
  const res = await schttp({
    url: '/user-api/gift_card/query',
    method: 'POST',
    data: {
      query_zero_balance: 1,
      country_id: await UserInfoManager.get({ key: 'Country', actionType: 'getCardList' })?.countryId || '',
      scene: 'checkout'
    },
    useBffApi: true
  })
  return res?.info || {}
}

const updateCheckoutPrice = ({ cardNo, cardPin, cardInfo }) => 
  new Promise(resolve => {
    window.checkoutEventCenter.emit('update-total', {
      opt: {
        ...caculateData.value,
        gift_card_no: cardNo,
        gift_card_pin: cardPin,
        use_card_scene: (!cardInfo || !!cardPin) ? 0 : 1
      },
      cb: async(res) => {
        const { msg, code } = res
        console.log('🐬🐬🐬 es: 计价 res', res, cardInfo)
        const isSuccessCode = [0, 300369].includes(+code)
        if (+code === 0) {
          if(cardInfo) {
            selectedItemCalculate.value = cardInfo
          }
          cardNo && handleGiftLimitDrawer()
        }
        if (msg && [0, 300364, 301711, 301717, 300365, 300368, 301716].includes(+code)) {
          SMessage({ message: msg, type: isSuccessCode ? 'success' : 'error' })
        }
        resolve({ ...res, cardInfo })
      }
    })
  })

const onFindCard = ({ cardNo, tabIndexs = [0, 1] }) => {
  const { available_card_list = [], unavailable_card_list = [] } = getCardsMap.value
  for(let i = 0; i < tabIndexs.length; i++) {
    const list = i === 0 ? available_card_list : unavailable_card_list
    const index = list.findIndex(item => item.card_no === cardNo)
    if (index !== -1) {
      giftCardRef.value?.goTab(i)
      giftCardRef.value?.goScroll(index)
      return {
        isFind: true,
        isAvailable: i === 0,
        item: list?.[index]
      }
    }
  }
  return {
    isFind: false,
    isAvailable: false,
    item: null
  }
}

const handleRisk = (resData, isDrawer) => {
  // 风控挑战（极验、短信、邮箱验证)
  const cb = result => {
    const { isSuccess, msg } = result
    if (Boolean(isSuccess)) {
      const ref = isDrawer ? giftCardRef.value?.$refs?.componentRef?.$refs?.bsBindGiftCardRef : bsBindGiftCardRef.value
      if(ref) {
        ref?.checkGiftCard({
          reskId: resData?.info?.risk_id,
          validate_token: resData?.info?.extend_info?.validate_token
        })
      }
    } else {
      msg && SMessage({ message: msg, type: 'error' })
    }
  }
  verifyManager.doVerify(resData?.info?.extend_info, cb)
}

const handleGiftLimitDrawer = () => {
  const { giftCardLimitGoods = [] } = handleLimitGoods({
    mallStoreInfos: checkout.value?.mall_caculate_info?.mall_store_infos || [],
    carts: checkout.value?.results?.carts?.carts || [],
  })
  if (giftCardLimitGoods.length) {
    assignState({
      giftCardLimitGoods: giftCardLimitGoods,
    })
  }
}

// 分场景,在弹窗中和页面上
const onBindCard = async(res, isDrawer) => {
  console.log('🐬🐬🐬 es: bind card res', res)
  // 用户输入的卡信息
  const { card_no, card_pin = '', isBindCard = false, resData = {} } = res
  if (isBindCard) {
    // 绑卡信息, 成功才有 info
    const { code, msg, info = {} } = resData
    const isBindCardSuccess = [0, 331802].includes(+code)
    // 绑卡提示
    bindCardErrorTips.value = isBindCardSuccess ? '' : msg
    if (msg && +code !== 331802) {
      SMessage({ message: msg, type: isBindCardSuccess ? 'success' : 'error' })
    }
    if ([0, 331802].includes(+code)){
      if (+code === 0) {
        cardCache.value = cardCache.value.filter((item => item.card_no !== info.card_no))
        apiCardsMap.value = await getCardListData() 
      }
      const { isFind, isAvailable, item } = onFindCard({ cardNo: card_no })
      if (+code === 331802) {
        SMessage({ message: isAvailable ? language.value.SHEIN_KEY_PC_33334 : language.value.SHEIN_KEY_PC_33335, type: isAvailable ? 'success' : 'error' })
      }
      if (isFind && isAvailable) {
        // 在外部才要计价
        if (isDrawer) {
          selectedItemDrawer.value = item
        } else {
          const { code } = await updateCheckoutPrice({ cardNo: item.card_no, cardInfo: item })
          if ([0, 300369].includes(+code)) {
            changeParamsStatus({
              type: 'caculateData',
              params: {
                gift_card_no: card_no,
                gift_card_pin: '',
                use_card_scene: 1,
              }
            })
          }
        }
      }
    } else if (+code === 331809 && info?.extend_info != null ) {
      handleRisk(resData, isDrawer)
    }
  } else {
    // 未绑卡, 通过获取卡片信息判断账号密码是否错误
    const { msg, cardInfo, code } = await getNewGiftCardInfo({ cardNo: card_no, cardPin: card_pin })
    SMessage({ message: msg, type: +code === 0 ? 'success' : 'error' })
    if (cardInfo) {
      cardCache.value = cardCache.value.filter((item => item.card_no !== cardInfo.card_no))
      const { isFind, isAvailable, item } = onFindCard({ cardNo: cardInfo.card_no })
      if (!isFind) {
        if (isDrawer) {
          giftCardRef.value?.goTab(0)
          giftCardRef.value?.goScroll(0)
        }
        cardCache.value.unshift(cardInfo)
      } else {
        // 找到, 并且在不可用列表
        SMessage({ message: isAvailable ? language.value.SHEIN_KEY_PC_33334 : language.value.SHEIN_KEY_PC_33335, type: isAvailable ? 'success' : 'error' })
        if (!isAvailable) return
      }
      if (isDrawer) {
        // 抽屉内,只有点击 confirm 按钮才是用卡, 而这里只是作为临时存储, 如果用户点击关闭按钮, 需要恢复上一个用卡成功状态, 因此不能使用 selectedItemCalculate
        selectedItemDrawer.value = isFind ? item : cardInfo
      } else {
        const { code } = await updateCheckoutPrice({ cardNo: card_no, cardPin: isFind ? '' : card_pin, cardInfo: isFind ? item : cardInfo })
        if ([0, 300369].includes(+code)) {
          changeParamsStatus({
            type: 'caculateData',
            params: {
              gift_card_no: card_no,
              gift_card_pin: isFind ? '' : card_pin,
              use_card_scene: isFind ? 1 : 0,
            }
          })
        }
      }
    }
  }
}

const onConfirm = async(item) => {
  const { card_no = '', card_pin = '' } = item || {}
  changeParamsStatus({
    type: 'caculateData',
    params: {
      gift_card_no: card_no,
      gift_card_pin: card_pin,
      use_card_scene: (!item || !!card_pin) ? 0 : 1,
    }
  })
  const { code } = await updateCheckoutPrice({
    cardNo: card_no,
    cardPin: card_pin,
    cardInfo: item,
  })
  if (+code === 0) {
    selectedItemCalculate.value = item
    visible.value = false
  }
}


const handleOpen = async() => {
  if (disableCreate.value) {
    apiCardsMap.value = await getCardListData()
    giftCardRef.value?.goTab(0)
    visible.value = true
    isShowRedDot.value = false
    selectedItemDrawer.value = cloneDeep(selectedItemCalculate.value)
  }
}

const onClose = () => {
  visible.value = false
}

const viewLimitReason = () => {
  changeParamsStatus({
    type: 'freezTipsAndGoods',
    params: {
      show: true,
      type: 'giftCard',
      dialogTitle: language.SHEIN_KEY_PC_33262
    },
  })
}

const onCustomEvent = ({ key, data = {} } = {}) => {
  console.log('🐬🐬🐬 es: onCustomEvent', key, data)
  switch(key) {
    case 'confirm':
      onConfirm(data.item)
      break
    case 'bind-card':
      onBindCard(data, true)
      break
    case 'close':
      onClose()
      break
    default:
      console.log('no event')
  }
}

onMounted(async() => {
  const ftoken = await InitFtokenPromise(true)
  verifyManager.init({
    axiosInstance: schttp,
    axiosConfig: {
      headers: { 'x-ftoken': ftoken }
    }
  })
  apiCardsMap.value = await getCardListData() 
  nextTick(() => {
    openPopover.value = true
  })
  setTimeout(() => {
    // 5s 后关闭提示
    openPopover.value = false
  }, 5000)
})
</script>
<style lang="less">
.new-giftcard {
  margin-bottom: 16px;
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__list {
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 5px;
    .red-dot {
      position: absolute;
      background-color: red;
      top: 0;
      right: -8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }
  &__bind {
    margin-top: 8px;
  }
  &__usetext {
    margin-top: 8px;
    color: #198055;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__use {
    width: 196px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 8px;
    .discount {
      padding: 6px 4px;
      color: #FFF;
      text-align: center;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: #FF5248;
      white-space: nowrap;
    }
    .sn {
      width: 100%;
      text-wrap: nowrap;
      overflow: hidden;
      color: #FA6338;
      text-overflow: ellipsis;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6px 16px 6px 8px;
      // border: 0.5px solid #FA6338;
      background: #FFEDEB;
    }
    .close {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width:0;
      height:0;
      border: 10px solid;
      border-color: #000 #000 transparent transparent;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }
  &__balance {
    margin-top: 8px;
    color: #000;
    text-align: justify;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__limit {
    cursor: pointer;
    color: #2d68a8;
    margin: 8px 0;
  }
}
</style>
