export function handleCouponGoodsList(cartsData, goodsList) {
  const newArr = []
  if (!goodsList?.length || !cartsData?.length) return newArr
  cartsData.map(item => {
    if (goodsList.some(val => val?.cart_id == item?.id)) {
      newArr.push(item)
    }
  })
  return newArr
}

// 30 天最低价 A ----- suggested_sale_price
// 原价 B -------retailPrice
// 促销活动后的价格 C ---- unit_price_before_coupon

/**
 * 获取de合规价格类型 pageId=1384306511
 * @param {Object} item
 * @returns {Number}
 */
export function getSuggestedPriceType({ suggestedSalePrice, retailPrice, unitPriceBeforeCoupon }) {
  if (suggestedSalePrice > 0) {
    if (suggestedSalePrice < retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 1
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 2
      }
    } else if (suggestedSalePrice === retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 3
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 4
      }
    }
    return 5
  } else if (suggestedSalePrice === 0) {
    return 6
  }
  return null
}

export function getSuggestedPriceTypeByCartItem(item) {
  return getSuggestedPriceType({
    suggestedSalePrice: +item.product?.suggested_sale_price?.amount || 0,
    retailPrice: +item.product?.retailPrice?.amount || 0,
    unitPriceBeforeCoupon: +item.unit_price_before_coupon?.amount || 0
  })
}
