import { computed } from 'vue'
import { template } from '@shein/common-function'


export default ({ language = {}, cartInfo = {}, underPriceAbtValue, flashSaleGroupList, integrityPromotionInfo, promotionInfo = {} } = {}) => {
  const promotionDiscountInfo = computed(() => {
    return cartInfo.promotion_discount_info || []
  })

  const promotionList = computed(() => {
    const promotionMap = {}
    const showDetailTipTids = [1, 9, 14, 15, 16, 17, 18, 19, 25, 26]
    const showTitleTipTids = [2, 3, 4, 8, 10, 11, 12, 13, 22, 24, 29, 30, 31, 32, 34]
    const titleLangMap = {
      '2': language.SHEIN_KEY_PC_15751,
      '3': language.SHEIN_KEY_PC_17697, // 限时折扣
      '4': language.SHEIN_KEY_PC_15751,
      '4-0': language.SHEIN_KEY_PC_15751,
      '4-8': language.SHEIN_KEY_PC_30894,
      '8': language.SHEIN_KEY_PC_17698, // 专享折扣
      '9': language.SHEIN_KEY_PC_34286, // 组合购
      '10-1': language.SHEIN_KEY_PC_15950, // 常规闪购
      '10-2': language.SHEIN_KEY_PC_23050, // 新人闪购
      '10-3': language.SHEIN_KEY_PC_23052, // EXCLUSIVE（特殊闪购）
      '10-4': language.SHEIN_KEY_PC_27297, // 分人群专区闪购
      '10-5': language.SHEIN_KEY_PC_27297, // 不分人群专区闪购
      '10-6': language.SHEIN_KEY_PC_29870, // 品牌特卖
      '11': language.SHEIN_KEY_PC_15950, // 直播闪购
      '12': language.SHEIN_KEY_PC_17699, // 会员专属折扣
      '13': language.SHEIN_KEY_PC_15966,
      '22': language.SHEIN_KEY_PC_15966,
      '24': language.SHEIN_KEY_PC_17701,
      '29': language.SHEIN_KEY_PC_22977, // 付费会员折扣
      '30': underPriceAbtValue ? language.SHEIN_KEY_PC_24789 : language.SHEIN_KEY_PC_24790, // 一口价
      '31': language.SHEIN_KEY_PC_34324, // 百亿补贴
      '32': language.SHEIN_KEY_PC_27371, // AB定价（Special coupon）
      '34': language.SHEIN_KEY_PC_23163 // 保价活动
    }
    
    promotionDiscountInfo.value?.forEach(item => {
      const id = item.id
      const tid = +item.typeId
      const promotionLogoType = item.promotion_logo_type
      // Price Drop 情况
      // 新人专享价（NEW USER EXCLUSIVE PRICE）
      if (tid == 31 ) {
        if(promotionLogoType == 3) {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PC_27730,
            typeId: tid,
            id: id,
            promotionLogoType
          }
        } else if(promotionLogoType != 1 && promotionLogoType != 3) {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PC_28264,
            typeId: tid,
            id: id,
            promotionLogoType
          }
        } else {
          promotionMap[id] = {
            tip: language.SHEIN_KEY_PC_28264,
            typeId: tid,
            id: id,
            promotionLogoType
          }
        }
      }
  
      const isShowDetailTip = showDetailTipTids.includes(tid)
      const isShowTitleTip = showTitleTipTids.includes(tid)
  
      if (!promotionMap[id]) promotionMap[id] = { typeId: tid, id: id }
  
      if (isShowDetailTip) {
        let promotionInfoContent = integrityPromotionInfo?.[id]?.content || {}
        if (promotionInfoContent.tips) {
          const tipsIndex = promotionInfoContent.tipsIndex
          const tipsList = promotionInfoContent.tips.list
          promotionMap[id].tip = tipsList[tipsIndex] || ''
          promotionMap[id].name = tipsList[tipsIndex] || ''
          if (tid === 9 && +promotionLogoType === 19) {
            // 组合购标题使用多语言
            // 分区数
            const poolLength = promotionInfo.pool?.find(item => item.promotion_id == id)?.pool_diff?.length
            const ruleIndex = promotionInfo.range?.find(item => item.promotion_id == id)?.range || 0
            // 折扣值
            const discountValue = integrityPromotionInfo?.[id]?.rules?.[ruleIndex - 1]?.discount?.value
            promotionMap[id].name = template(poolLength, 100 - discountValue * 100, titleLangMap[tid])
          }
        }
      } else if (isShowTitleTip) {
        // 闪购活动
        if (tid === 10) {
          const { flash_type } = integrityPromotionInfo?.[id] || {}
          
          if ([4, 5].includes(flash_type) && flashSaleGroupList?.[id]?.[0].promotionTag?.typeIdText) {
            // 要求与商品行展示的活动名称一致
            promotionMap[id].tip = flashSaleGroupList?.[id]?.[0].promotionTag?.typeIdText // 获取闪购活动第一个商品的 typeIdText
          } else {
            promotionMap[id].tip = titleLangMap[`${tid}-${flash_type}`]
          }

          promotionMap[id].flashType = flash_type
        } else if (tid === 4) { // 全场满赠（全场满赠、付费会员权益礼物）
          const { promotion_logo_type = '0' } = integrityPromotionInfo[id] || {}
          // typeId=4都是增品，所以用free gift做文案兜底
          promotionMap[id].tip = titleLangMap[`${tid}-${promotion_logo_type}`] || titleLangMap[`${tid}-0`]
        } else if (tid != 31 || (tid === 31 && +promotionLogoType === 21)) {
          promotionMap[id].tip = titleLangMap[tid]
        }
  
        promotionMap[id].name = promotionMap[id].tip

        let activityName = `<em>${promotionMap[id].tip}</em>`
        if(tid == 29) {
          activityName = `<em class="prime">${promotionMap[id].tip}</em>`
          promotionMap[id].icon = 'prime'
        } else if(tid == 10 && integrityPromotionInfo?.[id]?.flash_type == 1) {
          activityName = `<em class="flash">${promotionMap[id].tip}</em>`
          promotionMap[id].icon = 'flash'
        }

        promotionMap[id].tip = template(activityName, language.SHEIN_KEY_PC_29251)
      } else {
        const discount = cartInfo?.total_discount_price?.amountWithSymbol
        promotionMap[id].tip = template(`<em>${discount}</em>`, language.SHEIN_KEY_PC_29252)
      }
    })
  
    return Object.values(promotionMap)
  })

  return {
    promotionList
  }
}
