/* eslint-disable no-undef */
/* eslint-disable require-atomic-updates */
import schttp from 'public/src/services/schttp/index'
import { formatThirdLoginOptions, reloadOneTrustBanner, phoneCodeCache } from './util'
import { getFuzzyDefaultLocation } from '@login/utils/main'
import JumpCountry from 'public/src/pages/common/orders/jumpCountry.js'
import { setAuthUserInfo } from '@login/utils/auth.js'

let langPath
if (typeof window === 'object' && typeof window.gbCommonInfo === 'object') {
  langPath = window.gbCommonInfo.langPath
}

export function getFtoken() {
  return ''
}

function requestPromise(type, url, params, oheaders = {}, options = {}) {
  const headers = { ...oheaders }
  const method = type.toLowerCase()
  const soptions = { method: type, url, headers, ...options }
  if (method == 'get') soptions.params = params
  else soptions.data = params

  return schttp(soptions).catch((err) => ({ code: -1, msg: err.message }))
}

export async function request(type, url, params, headers = {}, options = {}) {
  const data = await requestPromise(type, url, params, headers, options)
  return data
}

// 登录注册统一处理入口
export const commonDealWith = (res, options = {}) => {
  if (res && res.code == 0) {
    const { type = '', params } = options
    try {
      setAuthUserInfo(res)
      reloadOneTrustBanner(res)
    } catch (error) {
      console.log('onetrust:' + error)
    }
    if (type == 'phone' && params?.area_abbr)
      phoneCodeCache.set(params.area_abbr, 1)
  }
}

/**
 * 三方登录统一方式，为了简化流程，将参数统一一致
 * @type type 三方登录类型 7（邮箱），12（google），11（facebook）,13(vk), 15(line), 16(kakao), 17(naver)
 * @param reqData 用户数据
 */
export async function loginThird(type, reqData) {
  const vparams = formatThirdLoginOptions(type, reqData)
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request(
    'post',
    `${langPath}/api/auth/loginThird/update?type=${type}`,
    vparams
  )
  commonDealWith(data)
  return data
}

/**
 * 绑定邮箱和社交方式
 * @param {Object} options 参数
 * @param {string} options.email 邮箱
 * @param {string} options.type 绑定的登录方式(7: 邮箱, 12: Google, 11: FaceBook, 13: VK)
 * @param {string?} options.password 密码
 * @param {string?} options.social_id 第三方社交渠道id
 * @param {string?} options.access_token 第三方鉴权token
 * @param {function} cb 回调函数
 */
export async function loginBind(options) {
  const data = await request('post', `${langPath}/api/auth/loginBind/update`, options)
  return data
}

export async function thirdLoginLocate(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/thirdLocate/update`,
    params
  )
  return data
}

export async function thirdLoginCodeSend(params) {
  const iswathsapp = params.send_type == 1
  // whatsapp暂时不走新接口
  if (!iswathsapp) {
    params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
    const data = await request('post', `/api/auth/sendUicsCode/get`, params)
    return data
  }
  const data = await request('post', `${langPath}/api/auth/sendCode/get`, params)
  return data
}

export async function codeVerfiyLogin(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/codeVerify/get`,
    params
  )
  commonDealWith(data)
  return data
}

function sortPhoneArea(list) {
  if (list.length == 0) return
  list.sort((v1, v2) => {
    let name1 = v1?.name || ''
    let name2 = v2?.name || ''

    if (!name1 || !name2) return 0
    const len = name1.length

    let flag = 0

    for (let i = 0; i < len; i++) {
      const ch1 = name1.charAt(i)
      const ch2 = name2.charAt(i)

      if (ch1 > ch2) {
        flag = 1
        break
      }
      if (ch1 < ch2) {
        flag = -1
        break
      }
    }
    return flag
  })
}

function formatSupportsPhoneAreaCodes(data) {
  const list = []
  const { PHONE_SUPPORT_COUNTRYS } = gbCommonInfo
  PHONE_SUPPORT_COUNTRYS.forEach((k) => {
    const v = data.find((v1) => v1.value == k)
    if (v) {
      list.push({
        code: v.phone_code.replace('+', ''),
        abbr: v.value,
        value: v.value,
        name: v.country,
        siteUid: v.siteUid || '',
      })
    }
  })
  sortPhoneArea(list)
  return list
}

function formatAllPhoneAreaCodes(data) {
  const list = []
  data.forEach((item) => {
    list.push({
      code: item.phone_code?.replace('+', ''),
      abbr: item.value,
      name: item.country,
    })
  })
  return list
}

// 获取手机国家号区码
export async function getPhoneAreaCode() {
  const data = await JumpCountry.getLocationList()
  return {
    support: formatSupportsPhoneAreaCodes(data),
    all: data,
    allFormat: formatAllPhoneAreaCodes(data || []),
  }
}

// 手机号码登陆
export async function phoneLoginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const timestap = Date.now()
  params.timestap = timestap
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const headers = {
    canvas: gbCommonInfo._loginCanvas,
    webgl: gbCommonInfo._loginWebgl,
  }
  const data = await request(
    'post',
    `${langPath}/api/auth/phoneSignin/update`,
    params,
    headers
  )
  commonDealWith(data, { type: 'phone', params })
  return data
}

// 手机号码注册
export async function phoneRegisterSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const headers = {
    canvas: gbCommonInfo._loginCanvas,
    webgl: gbCommonInfo._loginWebgl,
  }
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request(
    'post',
    `${langPath}/api/auth/phoneSignup/create`,
    params,
    headers
  )
  commonDealWith(data, { type: 'phone', params })
  return data
}

// 手机号码检测
export async function phoneCheckSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/phoneValidate/get`,
    params
  )
  return data
}

// 手机号码绑定
export async function phoneBindSer(params) {
  const headers = {
    canvas: gbCommonInfo._loginCanvas || '',
    webgl: gbCommonInfo._loginWebgl || '',
  }
  const data = await request(
    'post',
    `${langPath}/api/auth/aliasBind/update`,
    params,
    headers
  )
  return data
}

// 手机号码忘记密码
export async function phoneForgetSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/phoneForget/get`,
    params
  )
  return data
}

// 重置密码
export async function resetPasswordSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/resetPassword/update`,
    params
  )
  return data
}

export async function getMemeberList() {
  const data = await request('post', `${langPath}/api/auth/accountList/query`)
  const res =  {
    account_list: data?.info?.account_list || [],
    subscribe_status: data?.info?.subscribe_status || ''
  }
  return res
}

export async function emailLoginSer(params) {
  const timestap = Date.now()
  params.timestap = timestap
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const headers = {
    canvas: gbCommonInfo._loginCanvas,
    webgl: gbCommonInfo._loginWebgl,
  }
  const data = await request(
    'post',
    `${langPath}/api/auth/emailSignin/update`,
    params,
    headers
  )
  commonDealWith(data)
  return data
}

export async function emailSignupSer(vparams) {
  const headers = {
    canvas: gbCommonInfo._loginCanvas,
    webgl: gbCommonInfo._loginWebgl,
  }
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request(
    'post',
    `${langPath}/api/auth/emailSignup/create`,
    vparams,
    headers
  )
  commonDealWith(data)
  return data
}

export async function checkRiskSer(params) {
  const data = await request('post', `/api/user/center/checkRisk/create`, params)
  return data
}

export async function googleLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request(
    'post',
    '/api/auth/googleLogin/update?oldType=1',
    vparams
  )
  commonDealWith(data)
  return data
}

export async function facebookLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/fbLogin/update', vparams)
  commonDealWith(data)
  return data
}

export async function vkLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/vkLogin/update', vparams)
  commonDealWith(data)
  return data
}

export async function lineLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/lineLogin/update', vparams)
  commonDealWith(data)
  return data
}

export async function kakaoLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/kakaoLogin/update', vparams)
  commonDealWith(data)
  return data
}

export async function naverLoginSer(vparams) {
  vparams.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/naverLogin/update', vparams)
  commonDealWith(data)
  return data
}

// 查询已登录用户条款
export async function checkMemeberCluaseSer(params) {
  const data = await request('post', `${langPath}/api/auth/clause/get`, params)
  return data
}
// 检测地区协议类型
export async function checkLocationClauseSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/clause/checkCountry/get`,
    params
  )
  return data
}
// 检测用户位置
export async function checkUserGeoSer({ cache = false } = {}) {
  // 取缓存数据
  if (cache) {
    try {
      const cacheData = sessionStorage.getItem('__geo__') || null
      const val = JSON.parse(cacheData) || ''
      if (val) return val
    } catch (e) {
      //e
    }
  }
  const data = await request('post', `${langPath}/api/auth/userGeo/get`)
  if (data?.info?.location?.country_id) {
    sessionStorage.setItem('__geo__', JSON.stringify(data))
  }
  return data
}

// 获取当前站点支持的国家列表
export async function getSiteUidSupportsLocation() {
  const list = await JumpCountry.getLocationList()
  const { SiteUID } = gbCommonInfo
  const supportCountry = list.filter((v) => {
    if (SiteUID == 'www') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'www'
    }
    if (SiteUID == 'rw') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'rw'
    }
    return v.siteUid == SiteUID
  })
  return supportCountry
}

/**
 * 获取当前用户的默认地区
 */
export async function getDefaultLocationWithIp() {
  const geo = await checkUserGeoSer()
  const locationId = geo?.info?.location?.country_id || ''
  const supports = await getSiteUidSupportsLocation()

  // 当前ip区域是否在当前站点支持的地区列表
  let target = supports.find((v) => v.id == locationId)

  const defaultLocation = getFuzzyDefaultLocation(supports) || supports[0] || {}

  const location = {
    geo: geo?.info?.location || {}, // ip
    def: defaultLocation || {}, // default
    code: target?.value || defaultLocation?.value || '', // target
    id: target?.id || defaultLocation?.id || '',
    issupport: !!target,
  }
  return location
}
// 获取一站多合规信息
export async function getMixPrivacyInfo() {
  const { id, issupport } = await getDefaultLocationWithIp()

  const { info = {} } = await checkLocationClauseSer({ locationId: id })

  return {
    id,
    issupport, // ip所属国家是否在支持区域
    privacyType: info?.clause_country_type || 0,
  }
}

// 邮箱忘记密码邮件发送
export async function sendForgotEmailSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/forgetPassword/get`,
    params
  )
  return data
}

// 检测关联账号一致性
export async function checkRelationAccSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/relationCheck/get`,
    params
  )
  return data
}

// googleonetab 一键登陆
export async function googleOneTabSer(params) {
  const data = await request(
    'post',
    `${langPath}/api/auth/googleOneTabRegister/update`,
    params
  )
  commonDealWith(data)
  return data
}

export async function sendMailForRegisterSer(params) {
  const data = await request('post', '/api/auth/sendMailForRegister/update', params)
  return data
}

// 新版手机号邮箱绑定接口
export async function bindAliasSer(params) {
  const data = await request('post', `/api/auth/aliasBind/update`, params)
  return data
}

export async function aliasLocationSer (params) {
  const timestap = Date.now()
  params.timestap = timestap
  const data = await request('post', `/api/auth/location/get`, params)
  return data
}


export async function zendeskLoginSer () {
  const url = `/api/auth/zendeskLogin/get${window.location.search}`
  const data = await request('get', url)
  return data
}
