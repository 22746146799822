<template>
  <!-- 所有促销活动(包含A类、B类) -->
  <PromotionIndex 
    v-if="!isSuggested"
    :name="language.SHEIN_KEY_PC_29869 + ':'"
    :total-price="caculateInfo.total_promotion_discount"
    :promotion-discount-list="promotionDiscountList"
  />
  <div v-else>
    <!-- A类促销活动 -->
    <PromotionIndex 
      :name="promotionNameA + ':'"
      :total-price="caculateInfo.every_body_promotion_discount"
      :promotion-discount-list="promotionDiscountListA"
    />
    <!-- 存在B类才展示everybody price -->
    <CurrentPrice 
      v-if="
        !hideCurrentPrice &&
          (caculateInfo.exclusive_promotion_discount && 
            +caculateInfo.exclusive_promotion_discount.amount > 0) && 
          (caculateInfo.every_body_price && 
            +caculateInfo.every_body_price.amount > 0)
      "
    />
    <!-- B类促销活动 -->
    <PromotionIndex 
      :name="promotionNameB + ':'"
      :total-price="caculateInfo.exclusive_promotion_discount"
      :promotion-discount-list="promotionDiscountListB"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

import PromotionIndex from './PromotionIndex.vue'
import CurrentPrice from './CurrentPrice.vue'

defineProps({
  hideCurrentPrice: {
    type: Boolean,
    default: false
  }
})

const store = useStore()

const promotionDiscountList = ref([])
const promotionDiscountListA = ref([])
const promotionDiscountListB = ref([])

const isSuggested = computed(() => store.getters.isSuggested)
const isSuggestedInFr = computed(() => store.getters.isSuggestedInFr)
const isSuggestedInDe = computed(() => store.getters.isSuggestedInDe)
const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const promotionList = computed(() => store.state.promotionList || [])

const caculateInfo = computed(() => checkout.value?.mall_caculate_info || {})

const getCategoryName = (params) => {
  return new Promise(resolve => {
    schttp({
      url: `/api/checkout/promotion/getPromotionById`,
      method: 'POST',
      data: params,
    }).then(res => {
      if (res?.code == 0 && res?.info) {
        resolve(res.info || {})
      }
      resolve({})
    }).catch((err) => {
      console.error('err', err)
      resolve({})
    })
  })
}


const promotionNameA = computed(() => {
  if (isSuggestedInDe.value) return language.value.SHEIN_KEY_PC_33892
  if (isSuggestedInFr.value) return language.value.SHEIN_KEY_PC_33891
  return language.value.SHEIN_KEY_PC_30550
})

const promotionNameB = computed(() => {
  return isSuggestedInDe.value ? 
    language.value.SHEIN_KEY_PC_31106 : 
    language.value.SHEIN_KEY_PC_30552
})

const getPromotionDiscountList = async() => {
  const discountList = caculateInfo.value?.promotion_discount_info || []
  const detail = promotionList.value || []
 
  // 价格明细中促销去除展示 32
  let _discountList = await Promise.all(discountList.map(async item => {

    let target = detail.find(_ => _.id == item.id)
    let discountPrice = item.discount_price 
    // 33 是品类首津
    if (item.typeId == 33) {
      const promotionId = item?.promotion_ids?.[0]
      if (promotionId) { 
        const { list = [] } = await getCategoryName({ promotionId })
        const cat_name_multi = list?.find(val => val?.promotion_id == promotionId)?.category_info[0]?.cat_name_multi
        const name = !!cat_name_multi ? template(cat_name_multi, language.value?.SHEIN_KEY_PC_30876) : language.value?.SHEIN_KEY_PC_30873
        target && (target.name = name)
      }
    } else if (item.typeId == 34) {
      // 34 是 保价活动
      discountPrice = item.discount_price4_insurable
    }
    return Object.assign(item, { 
      name: target?.name || language.value?.SHEIN_KEY_PC_33983, 
      discount_price: discountPrice 
    })
  }))

  _discountList?.filter(_ => _.typeId != 32) || []

  if(+caculateInfo.value?.other_discount_price?.amount > 0) {
    _discountList.push({
      name: language.value?.SHEIN_KEY_PC_17702,
      id: 'special',
      discount_price: caculateInfo.value?.other_discount_price,
      cart_ids: caculateInfo.value?.other_discount_for_cart_ids,
      every_body_price_type: 1, // 聚合在A类下展示
    })
  }

  promotionDiscountList.value = _discountList?.sort((a, b) => +b.discount_price?.amount - +a.discount_price?.amount) || []
  promotionDiscountListA.value = promotionDiscountList.value?.filter(_ => _.every_body_price_type == 1 && _.typeId != 33)
  promotionDiscountListB.value = promotionDiscountList.value?.filter(_ => _.every_body_price_type == 2) // 品类首津在B类活动
}

watch([() => caculateInfo.value?.promotion_discount_info, () => promotionList.value], ([newValA, oldValA], [newValB, oldValB]) => {
  if (typeof window !== 'undefined' && (newValA != oldValA || newValB != oldValB)) {
    getPromotionDiscountList()
  }
}, {
  immediate: true
})
</script>

