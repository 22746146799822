// import { watch } from 'vue'
import schttp from 'public/src/services/schttp'

export const useDayTime = ({ state, commit }) => {
  const { locals, defaultShipping, checkout, storeData, buyNowNo } = state
  const getCartList = (carts) => {
    const cart_list = []
    const goods_sn_list = []
    carts?.forEach(item => {
      let isQuickShip = ''
      if (locals.SiteUID?.indexOf('br')) {
        // 巴西
        isQuickShip = +item?.sfs_flag === 1
      } else if (locals.SiteUID?.indexOf('jp')){
        // 日本
        isQuickShip = ''
      } else {
        isQuickShip = [1, 2].includes(+item?.real_quick_ship)
      }
      cart_list.push({
        cartId: item.id,
        goodsSn: item?.product?.goods_sn,
        isQuickShip,
        skuCode: item?.product?.sku_code,
        mallCode: item.mall_code,
        storeCode: item.store_code,
        isHl: [1, 2].includes(+item.large_ship)
      })
      goods_sn_list.push({
        goodsSn: item?.product?.goods_sn,
        mallCode: item.mall_code,
      })
    })
    return {
      cart_list,
      goods_sn_list
    }
  }

  const getTransportList = (mallList) => {
    const transport_list = []
    const scenes = [{
      // QS
      isQuickShip: true,
      isHl: false,
    }, {
      // 海陆运
      isQuickShip: false,
      isHl: true,
    }, {
      // 普通商品
      isQuickShip: false,
      isHl: false,
    }]
mallList?.forEach(({ shipping_methods, mall_code }) => 

      shipping_methods.forEach(({ transport_type }) => {
        scenes.forEach(({ isQuickShip, isHl }) => {
          transport_list.push({
            isQuickShip,
            transportType: transport_type,
            isHl,
            mallCode: mall_code,
          })
        })
      }))
    return transport_list
  }

  const updateDayTimeTip = async() => {
    const address = checkout.default_address
    const carts = checkout.results.carts.carts
    const mallList = checkout.results?.mallShippingMethods?.mall_list
    const transportType = defaultShipping?.[0]?.shipping_method?.transport_type || ''
    const { cart_list = [], goods_sn_list = [] } = getCartList(carts)
    const params = {
      addressInfoOther: address,
      has_shop_transit_address: !!storeData ? 1 : 0, 
      checkout_no: buyNowNo, 
      scene: 'checkout',
      transport_type: transportType,
      type: 2,
      day_time_params: {
        cart_list,
        transport_list: getTransportList(mallList),
        goods_sn_list,
      },
    }
    const { code, info = {} } = await schttp({
      url: '/api/common/getAgingInfo/get',
      method: 'POST',
      data: params
    })
    if(code == 0){
      commit('assignState',  { 
        dayTimeMap: {
          cartList: info?.cartList,
          transportList: info?.transportList,
          selectTransportType: transportType,
          hasEffectIgnoreAbt: info?.hasEffectIgnoreAbt
        }
      })
    } else {
      console.error(`newQuickShip err`)
    }
    console.log('🐬🐬🐬 es: dayTime Result', info)
  }

  // watch(() => defaultShipping?.[0]?.shipping_method?.type, (val) => {
  //   console.log('🐬🐬🐬 es: val', val)
  //   if (val) {
  //     updateDayTimeTip()
  //   }
  // }, {
  //   immediate: true,
  // })

  return {
    updateDayTimeTip,
  }
}



