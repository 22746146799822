<template>
  <div 
    :id="`shipping-options__container-${mallCode}`"
    class="shipping-options-wrap"
    :class="{'shipping-options-right': newAddressShipByAbt}"
  >
    <div
      v-if="
        checkout.default_address.addressId &&
          shippingMethods &&
          shippingMethods.length
      "
      class="checkout-shipping__effiency"
    >
      <div
        class="check-title"
      >
        <h4 
          tabindex="0" 
          class="check-title-content fsp-element"
        >
          {{ language.SHEIN_KEY_PC_14732 }}
        </h4>
      </div>

      <ShippingPrime 
        :mall-code="mallCode"
        :default-shipping-item="defaultShippingItem"
        :prime-coupon-count="primeCouponCount"
      />

      <!-- 运输方式列表 -->
      <ShippingOptionsRight 
        v-if="newAddressShipByAbt"
        class="shipping-options-fold"
        :shipping-methods="shippingMethods"
        :default-shipping-item="defaultShippingItem"
        :is-all-quick-ship="isAllQuickShip"
        :is-quick-ship-title="isQuickShipTitle"
        :desc-quick-ship-time="descQuickShipTime"
        :mall-code="mallCode"
        :prime-coupon-count="primeCouponCount"
        :more-position="positionConfig.position"
        :quick-ship-info="quickShipInfo"
        :add-item-content-info="addItemContentInfo"
        :default-selected-index="defaultSelectedIndex"
        @click-shipping="clickShipping"
      />
      
      <shipping-options-swiper
        v-else-if="shippingSlideByAbt"
        :shipping-methods="shippingMethods"
        :default-shipping-item="defaultShippingItem"
        :is-all-quick-ship="isAllQuickShip"
        :is-quick-ship-title="isQuickShipTitle"
        :desc-quick-ship-time="descQuickShipTime"
        :mall-code="mallCode"
        :prime-coupon-count="primeCouponCount"
        :more-position="positionConfig.position"
        :quick-ship-info="quickShipInfo"
        :add-item-content-info="addItemContentInfo"
        :default-selected-index="defaultSelectedIndex"
        @click-shipping="clickShipping"
      /> 
      <ul
        v-else
        v-cloak
        v-ada-radio-group
        class="shipping-drop shipping-options-fold"
      >
        <ShippingOptionsItem 
          v-for="(item, index) in shippingMethods"
          :key="index"
          :item="item"
          :index="index"
          :default-shipping-item="defaultShippingItem"
          :is-click-more="isClickMore"
          :is-all-quick-ship="isAllQuickShip"
          :is-quick-ship-title="isQuickShipTitle"
          :desc-quick-ship-time="descQuickShipTime"
          :mall-code="mallCode"
          :prime-coupon-count="primeCouponCount"
          :more-position="positionConfig.position"
          :quick-ship-info="quickShipInfo"
          :add-item-content-info="addItemContentInfo"
          :default-selected-index="defaultSelectedIndex"
          @click-shipping="clickShipping"
        />
        
        <li
          v-if="isShowMore"
          v-expose="{
            id: '1-11-1-54',
            data: viewMoreAnalysis
          }"
          v-tap="{
            id: '1-11-1-103',
            data: viewMoreAnalysis
          }"
          class="shipping-list-more"
          @click="clickMore"
        >
          {{ language.SHEIN_KEY_PC_22205 }}
          <sui_icon_more_down_12px_1 
            size="12px" 
            color="#2d68a8"
          />
        </li>
      </ul>

      <!-- 运费险选项 -->
      <div
        v-if="isShowInsurance"
        v-cloak
        class="insurance-tip"
      >
        <s-checkbox
          v-expose="{
            id: '1-11-1-111',
            data: insuranceAnalysisData
          }"
          :model-value="insuranceCheck"
          gap="0"
          :size="'size16'"
          :disabled="!!insuranceItem.isForceCheck"
          @change="clickInsurance"
          @click="nativeClickCheckbox"
        >
          <span>
            {{
              insuranceItem && insuranceItem.hint ? insuranceItem.hint : ''
            }}
            <strong
              v-if="insuranceItem.isFREE == 'FREE'"
              class="sui-label__success"
            >
              {{ language.SHEIN_KEY_PC_25900 }}
            </strong>
            <strong
              v-cloak
              v-else
            >
              {{
                insuranceItem &&
                  insuranceItem.insurance_price &&
                  insuranceItem.insurance_price.amountWithSymbol
              }}
            </strong>
          </span>
        </s-checkbox>
        <insurance-popover
          v-if="insuranceDescript"
          :description="insuranceDescript"
          :insurance-pop-analysis="insurancePopAnalysis"
        />
      </div>

      <!-- 新样式价格添加在此处 -->
      <div
        v-if="showMallPrice && totalPrice"
        class="platform-price"
      >
        <span>{{ language.SHEIN_KEY_PC_15024 + ': ' }}</span>
        <strong>{{ totalPrice.amountWithSymbol }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { template as _commonTemplate } from '@shein/common-function'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import checkoutMixin from 'public/src/pages/checkout/checkoutMixin.js'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose, tap } from 'public/src/pages/common/analysis/directive.js'
import { isShippingMethodDisabled } from 'public/src/pages/checkout/config/tools.js'

import ShippingOptionsSwiper from 'public/src/pages/checkout/vue_tpls/ShippingOptionsSwiper.vue'
import ShippingOptionsItem from 'public/src/pages/checkout/components/shipping_method/ShippingMethodItem.vue'
import ShippingPrime from 'public/src/pages/checkout/vue_tpls/shippingPrime.vue'
import InsurancePopover from 'public/src/pages/components/InsurancePopover.vue'
import ShippingOptionsRight from 'public/src/pages/checkout/vue_tpls/ShippingOptionsRight.vue'
import { sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/hooks/EventAggregator.js'


daEventCenter.addSubscriber({ modulecode: '1-11-1' })


export default {
  name: 'ShippingOptions',
  components: {
    ShippingOptionsItem,
    ShippingPrime,
    InsurancePopover,
    ShippingOptionsSwiper,
    ShippingOptionsRight,
    sui_icon_more_down_12px_1,
  },
  directives: { expose, tap },
  mixins: [checkoutMixin],
  props: {
    mallCode: {
      type: [String, Number],
      default: '',
    },
    shippingMethods: {
      type: Array,
      default: () => [],
    },
    cartsData: {
      type: Array,
      default: () => []
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      selectedType: '',
      isClickMore: false,
      defaultSelectedIndex: -1,
    }
  },
  computed: {
    ...mapState([
      'language',
      'caculateData', 
      'defaultShipping', 
      'mallInsuranceCheck', 
      'mallShippingMethods', 
      'changeShopType', 
      'coupon', 
      'shippingTypeDialog',
      'shippingLimitInfo',
      'storeData',
      'shippingTypeChangeInfo',
      'checkout',
      'shippingContentInfo',
      'usableCouponList',
      'disabledCouponList',
      'qsMallList'
    ]),
    ...mapGetters([
      'shoppingBagPosition', 
      'showQuickShipByAbt', 
      'orderInterceptByAbt',
      'isShopTransit',
      'newAddressShipByAbt',
      'isSiteMallList',
      'shippingSlideByAbt',
      'showAddEntranceByAbt',
      'showNewAddEntranceByAbt',
    ]),
    shippingPriceAll() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const targetCalcu = mall_list.find(mall => mall.mall_code == this.mallCode)
      const shipping_price_all = targetCalcu?.shipping_price_all || []

      return shipping_price_all
    },
    // 凑单免邮活动
    shippingContentFreeInfo() {
      return {
        detail: this.shippingContentInfo?.[this.mallCode] || {},
        origin: this.shippingContentInfo
      }
    },
    // 凑单券
    shippingCouponFreeInfo() {
      let couponActivity

      const nowTransportType = this.defaultShippingItem?.shipping_method?.transport_type
      let usableOriginCouponList = this.usableCouponList?.filter(_ => _.apply_for == 9 && _.shipping_discount_type == 0) || []
      let disabledOriginCouponList = this.disabledCouponList?.filter(_ => _.apply_for == 9 && _.shipping_discount_type == 0) || []

      const couponAddInfoList = this.handleShippingCouponAdd({ shippingMethods: this.shippingMethods, shippingPriceAll: this.shippingPriceAll, usableCouponList: usableOriginCouponList, disabledCouponList: disabledOriginCouponList })
      if(!!couponAddInfoList?.[0]?.transportType) {
        const nowCouponInfo = couponAddInfoList.find(_ => _.transportType == nowTransportType)
        if(!!nowCouponInfo) { // 优先寻找当前选中运输方式的
          couponActivity = {
            transportType: nowCouponInfo?.transportType,
            info: nowCouponInfo?.info,
            diffPrice: nowCouponInfo?.diffPrice,
            type: 'coupon'
          }
        } else { // 寻找未选中中差额最小的（如果存在多运输方式相同差额的，返回第一个运输方式的）
          const otherCouponInfo = JSON.parse(JSON.stringify(couponAddInfoList)).sort((a, b) => +a.diffPrice?.amount - +b.diffPrice?.amount)?.[0]
          couponActivity = {
            transportType: otherCouponInfo?.transportType,
            info: otherCouponInfo?.info,
            diffPrice: otherCouponInfo?.diffPrice,
            type: 'coupon'
          }
        }
      }

      return couponActivity
    },
    addItemContentInfo() {
      let shippingFreeActivity,
          couponActivity
      const { showShipSupplement, transportType, shippingInfoAvailable } = this.shippingContentFreeInfo?.detail
      const method = this.shippingMethods?.find(_ => _.transport_type == transportType)
      let isDisabled,
          targetPrice
      if(!!method) {
        isDisabled = isShippingMethodDisabled({ item: method, orderInterceptByAbt: this.orderInterceptByAbt })
        targetPrice = this.shippingPriceAll?.find(_ => _.transport_type == transportType)?.price
      }
      if(showShipSupplement && (!!method && !isDisabled && +targetPrice?.amount != 0)) {
        shippingFreeActivity = {
          transportType,
          detail: shippingInfoAvailable,
          info: this.shippingContentFreeInfo?.origin,
          diffPrice: shippingInfoAvailable?.price,
          type: 'activity'
        }
      }
      if(!!this.shippingCouponFreeInfo?.transportType) couponActivity = this.shippingCouponFreeInfo
      
      let contentInfo
      const shippingFreeDiffPriceUsd = shippingFreeActivity?.detail?.diff_price?.usdAmount
      const shippingFreeThresholdPriceUsd = shippingFreeActivity?.detail?.threshold_price?.usdAmount
      let progress = 0 // 凑单进度
      let shippingFreeProgress = 0 // 免邮活动凑单进度
      let couponProgress = 0 // 免邮券凑单进度
      if (shippingFreeDiffPriceUsd && shippingFreeThresholdPriceUsd) {
        shippingFreeProgress = 1 - (Number(shippingFreeDiffPriceUsd) / Number(shippingFreeThresholdPriceUsd))
        shippingFreeProgress = Math.floor(shippingFreeProgress * 100) / 100 // 两位小数，无需四舍五入
      }
      const couponDiffPriceUsd = couponActivity?.info?.needPrice?.usdAmount
      const couponGoodsTotlePriceUsd = couponActivity?.info?.apply_goods?.total_value?.usdAmount
      if (couponDiffPriceUsd && couponGoodsTotlePriceUsd) {
        couponProgress = 1 - (Number(couponDiffPriceUsd) / (Number(couponDiffPriceUsd) +  +  Number(couponGoodsTotlePriceUsd)))
        couponProgress = Math.floor(couponProgress * 100) / 100 // 两位小数，无需四舍五入
      }
      if(!!shippingFreeActivity?.transportType && !!couponActivity?.transportType) {
        contentInfo = +shippingFreeActivity?.diffPrice?.amount <= +couponActivity?.diffPrice?.amount ? shippingFreeActivity : couponActivity
        progress = +shippingFreeActivity?.diffPrice?.amount <= +couponActivity?.diffPrice?.amount ? shippingFreeProgress : couponProgress
      } else {
        contentInfo = !!shippingFreeActivity?.transportType ? shippingFreeActivity : (!!couponActivity?.transportType ? couponActivity : {})
        progress = !!shippingFreeActivity?.transportType ? shippingFreeProgress : (!!couponActivity?.transportType ? couponProgress : 0)
      }
      if (contentInfo.transportType && this.showAddEntranceByAbt && this.showNewAddEntranceByAbt) { // 可凑单免邮，且新展示逻辑开启。pageId=1451690127 on
        const { shipping_method } = this.defaultShippingItem
        const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
        const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
        const shipping_price_all = filterCalcu[0]?.shipping_price_all || []
        const shipping_price = shipping_price_all.filter(price => price.transport_type == shipping_method?.transport_type) || []
        const selectShippingPrice = shipping_price[0] || {}// 当前选中运输方式价格信息，包括原价，计算后价格等
        const selectShippingPricesUsd = selectShippingPrice?.price?.usdAmount
        if (shipping_method?.transport_type && +selectShippingPricesUsd === 0) {
          // 已有运输方式被选中，且选中的运费已免邮，不展示入口
          return {}
        }
        // 未免邮，凑单进度小于配置值，不展示入口
        if (progress < this.checkout?.CHECKOUT_SHOW_ADD_ENTRANCE_THRESHOLD_PROGRESS) {
          return {}
        }
      }
      return Object.assign(contentInfo, {
        activityContent: this.shippingContentFreeInfo?.origin,
        couponContent: this.shippingCouponFreeInfo?.info,
        progress
      })
    },
    viewMoreAnalysis() {
      if(typeof window == 'undefined') return {}

      return { 
        is_default: '-', 
        shipping_method: 'view_more', 
        shipping_time: '-', 
        shipping_fee: '-', 
        mall_code: this.mallCode, 
        shipping_time_desc: '-', 
        scenes: '-',
        is_folded: 0,
        sequence: 2,
        style: 'list',
        default_type: '-'
      }
    },
    showMallPrice() {
      const mallLenth = +this.defaultShipping?.length || 0
      return this.shoppingBagPosition && mallLenth > 1
    },
    insuranceDescript(){
      const { ControlFreightInFree } = this.checkout?.checkoutBFFAbtInfo.FreightInFree.param || {}
      // 1.退货险，金额为0，abt判断, gift_tips
      if(this.insuranceItem.insurance_type == '1' && this.insuranceItem.isFREE == 'FREE' && ControlFreightInFree === 'on' && this.insuranceItem.gift_tips ) return this.insuranceItem.gift_tips
      return this.insuranceItem?.description ? this.insuranceItem.description.replace(/\s/g, ' ') : ''
    },
    primeCouponCount() {
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      // coupon_dimension 1平台券，2mall券
      const primeCoupon = applyCouponsSuccess.filter(item => item.applyFor == 9 && item.typeId == 53 && (item.coupon_dimension == 1 || item.mallCode == this.mallCode)) || []
      return primeCoupon.length || 0
    },
    descQuickShipTime() {
      if (!this.isAllQuickShip) return ''
      return this.quickShipDescribe(this.quickShipInfo)
    },
    isAllQuickShip() {
      if (!this.showQuickShipByAbt || !this.cartsData?.length) return false
      const list = this.cartsData?.filter(item => item.real_quick_ship == 1) || []
      return list.length == this.cartsData.length
    },
    isQuickShipTitle() {
      // 特殊站点，不去判断qs库存
      const countryId = this.checkout?.default_address?.countryId
      const last_quickship_tag = this.qsMallList?.last_quickship_tag
      const specialSite = ['108'].includes(countryId)
      return !!(this.shippingMethods.length == 1 && (this.isAllQuickShip || (specialSite && last_quickship_tag)))
    },
    // 当前mall选中运输方式
    defaultShippingItem() {
      const filterDefauShip =
        this.defaultShipping?.find((mall) => mall.mall_code == this.mallCode) ||
        {}
      return {
        shipping_method: filterDefauShip.shipping_method,
        index: filterDefauShip?.index || 0,
      }
    },
    // 是否显示保险
    isDisplayShippingInsurance() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      return (
        mall_list.find((mall) => mall.mall_code == this.mallCode)
          ?.isDisplayShippingInsurance || 0
      )
    },
    insuranceItem() {
      const { forcedShipping } = this.checkout?.checkoutBFFAbtInfo || {}
      let insuranceMallData = this.checkout?.results?.insuranceMallData || {}
      const { mall_list = [] } = insuranceMallData
      if (!mall_list.length) return {}
      const filterList = mall_list.filter(
        (item) => item.mall_code == this.mallCode
      )
      const isForceCheck = forcedShipping?.param === 'forcedShippingGuarantee' && +filterList[0]?.is_force_tick === 1
      const { insurance_type, insurance_price } = filterList[0] || {}
      const isFREE = [0, 1].includes(+insurance_type) && insurance_price?.amount == 0 ? 'FREE' : ''
      return Object.assign({}, filterList[0] || {}, { isForceCheck, isFREE })
    },
    isShowInsurance() {
      const { insuranceItem, isDisplayShippingInsurance } = this
      const insuranceTypeFlag = isDisplayShippingInsurance == 0 && insuranceItem?.insurance_type == 1
      const insuranceHintType = insuranceItem.hint
      return !insuranceTypeFlag && insuranceHintType && insuranceItem.insurance_type != 3
    },
    insuranceCheck() {
      let mallInsuranceCheck = this.mallInsuranceCheck
      const filterInsurance =
        mallInsuranceCheck.find(
          (insurance) => insurance.mall_code == this.mallCode
        ) || {}
      return !!+filterInsurance?.use_insurance || false
    },
    totalPrice() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCaucl = mall_list.filter(
        (mall) => mall.mall_code == this.mallCode
      )
      return filterCaucl[0]?.total_price || {}
    },
    // 是否需要展示more强制开关
    isShowMoreSwitch() {
      const { show, position } = this.positionConfig
      if (!show || +position == 0 || this.shippingMethods.length <= +position) return false
      return true
    },
    positionConfig() {
      let show = false
      let position = 0

      for (let i = 0; i < this.shippingMethods?.length; i++) {
        // 若后端返回的is_fold=1，在现有运输方式排列顺序的基础上将该运输方式隐藏
        // 若后台配置了BC运输方式折叠，此时展示A方式，BCD方式被隐藏
        if (this.shippingMethods[i]?.is_fold === 1) {
          show = true
          position = i
          break
        }
      }

      return { show, position }
    },
    isShowMore() {
      if (!this.isShowMoreSwitch) return false
      const isShowMore = !this.changeShopType && !this.isClickMore
    
      return isShowMore
    },
    insuranceAnalysisData () {
      if(typeof window == 'undefined') return {}

      const { insuranceItem = {} } = this
      return {
        select_type: this.insuranceCheck ? 1 : 0,
        is_forced: insuranceItem.isForceCheck ? 1 : 0,
        insurance_type: insuranceItem.insurance_type,
        mall_code: insuranceItem.mall_code,
        default_value: insuranceItem.is_default
      }
    },
    insurancePopAnalysis() {
      if(typeof window == 'undefined') return {}
      return { isReport: true, id: '1-11-1-112', params: { mall_code: this.mallCode } }
    },
  },
  watch: {
    'defaultShippingItem.index': {
      deep: true,
      handler(v) {
        const { position } = this.positionConfig
        const currShippingMethod = this.shippingMethods[v] || {}
        let userChecked = -1
        if(currShippingMethod.is_available == 1 || !this.orderInterceptByAbt) userChecked = v

        if (userChecked != -1 && +v >= +position) this.isClickMore = true
      },
      immediate: true,
    },
    descQuickShipTime: {
      handler(v) {
        if (v) {
          if(typeof window == 'undefined') return 

          daEventCenter.triggerNotice({
            daId: '1-11-1-89',
            extraData: {
              quickship_tp: '1'
            }
          })
        }
      },
      immediate: true
    },
    addItemContentInfo: {
      handler() {
        this.exposeScene()
      },
      deep: true,
    },
  },
  mounted() {
    const filterDefauShip = this.defaultShipping?.find(mall => mall.mall_code == this.mallCode)
    this.defaultSelectedIndex = filterDefauShip?.index || 0
  },
  methods: {
    ...mapMutations([
      'assignState', 
      'updateCheckoutState',
    ]),
    ...mapActions([
      'handleShipInterceptModal',
    ]),
    template: _commonTemplate,
    exposeScene() {
      if(typeof window == 'undefined') return 

      // 多mall的时候不上报
      if(this.isSiteMallList) return

      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'checkout_shipping_add',
          type: !!this.addItemContentInfo?.transportType ? 1 : 0,
          progress: !!this.addItemContentInfo?.transportType ? this.addItemContentInfo?.progress : '-'// 当type=1时，上报当前邮费券或免邮活动的凑单进度百分比；当type=0时，上报"-"
        }
      })
    },
    // 获取各运输方式支持凑单的券信息
    handleShippingCouponAdd({ shippingMethods, shippingPriceAll, usableCouponList, disabledCouponList }) {
      // 运输方式满足凑单条件的券信息
      let couponAddInfoList = []
      if(!shippingMethods.length) return couponAddInfoList

      shippingMethods.forEach(method => {
        let couponInfo = {}
        const isDisabled = isShippingMethodDisabled({ item: method, orderInterceptByAbt: this.orderInterceptByAbt })
        const shipping_price = shippingPriceAll.find(price => price.transport_type == method.transport_type)
        // 去除 不可用、已免邮运输方式
        if(!isDisabled && +shipping_price?.price?.amount != 0) {
          const nowTransportType = method?.transport_type
          const usableTargetCouponList = usableCouponList.filter(_ => (!_.shipping_list || _.shipping_list?.map(i => i.shipping_method_code)?.includes(nowTransportType))) || []
          const disabledTargetCouponList = disabledCouponList.filter(_ => (!_.shipping_list || _.shipping_list?.map(i => i.shipping_method_code)?.includes(nowTransportType) && [2, 4, 9].includes(parseInt(_.reason, 10)))) || []
          // 可用券列表中不包含指定券且不可用券列表中包含指定券
          const couponShow = !usableTargetCouponList.length && !!disabledTargetCouponList.length

          if(couponShow) {
            // 选取距离门槛差额最小的指定券
            const targetCoupon = JSON.parse(JSON.stringify(disabledTargetCouponList)).sort((a, b) => +a.needPrice?.amount - +b.needPrice?.amount)?.[0]

            couponInfo = Object.assign(couponInfo, {
              transportType: nowTransportType,
              info: targetCoupon,
              diffPrice: targetCoupon.needPrice
            })
          }
        }

        if(!!couponInfo?.transportType) couponAddInfoList.push(couponInfo)
      })

      return couponAddInfoList
    },
    clickMore() {
      this.isClickMore = true
    },
    isOrderIntercept(item) {
      const interceptList = [1, 2, 3, 7]
      return this.orderInterceptByAbt && item.is_available == 0 && item.is_available_msg == 9 && interceptList.includes(+item.rule_info?.hint_type)
    },
    clickShipping({ item, addItemUpdate, isInterceptBack, skipShipIntercept }) {
      this.selectedType = item.transport_type
      const index = this.shippingMethods.findIndex(_ => _.transport_type == item.transport_type)
      // 点击more按钮后，点击展开后面的运输方式上报
      if (this.isShowMoreSwitch && index >= +this.positionConfig.position) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-65',
        })
      }

      // 点击切换前选中的是否为店配
      const isPrevStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.defaultShippingItem?.shipping_method?.transport_type)
      // 运输方式是否为店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(item.transport_type)
      // 切换到店配时，先判断是否有店配地址，没有则先弹出添加地址弹窗
      if (isStoreAddress && (!Object.keys(this.storeData || {}).length || this.storeData?.status != 1)) {
        window.checkoutEventCenter?.emit?.('add-store-address', { mall_code: this.mallCode, transport_type: item.transport_type })
        this.assignState({
          selectStoreAddressShippingInfo: {
            shippingMethod: item,
            mallCode: this.mallCode,
            isAdd: true
          }
        })
        return
      }

      const isChangeType = isPrevStoreAddress != isStoreAddress
      if(isChangeType || addItemUpdate) {
        if(addItemUpdate) {
          this.assignState({
            forceUpdateShippingMethod: {
              isUpdate: true
            }
          })
        }

        window.checkoutEventCenter.emit('update-shipping-method', { 
          address: this.checkout.default_address,
          isLimit: 1,
          shippingMethod: item,
          mallCode: this.mallCode,
          isInterceptBack,
          skipShipIntercept,
          modifyCart: !!addItemUpdate,
        })

        return
      }

      const isOrderIntercept = this.isOrderIntercept(item)
      if(isOrderIntercept) {
        // 不涉及商品-0,删除全部商品-1,删除部分商品-2,需修改地址-3
        const hintType = item.rule_info?.hint_type
        this.handleShipInterceptModal({ 
          hintType, 
          shippingMethod: item, 
          mallCode: this.mallCode
        })
        daEventCenter.triggerNotice({
          daId: '1-11-1-172',
          extraData: {
            rules_type: hintType,
            shipping_method_id: item.transport_type,
            mall_code: this.mallCode
          }
        })
        return
      }

      this.$emit('click-shipping', {
        item,
        index,
        mallCode: this.mallCode,
        transport_type: this.selectedType,
      })
    },
    clickInsurance() {
      this.$emit('click-insurance', this.mallCode)
    },
    nativeClickCheckbox (e) {
      e?.stopPropagation?.()
      daEventCenter.triggerNotice({
        daId: '1-11-1-110',
        extraData: this.insuranceAnalysisData
      })
    }
  },
  emits: ['click-shipping', 'click-insurance'],
}
</script>

<style lang="less" scoped>
.is-right {
  .margin-l(8px);
}

.checkout-shipping__effiency {
  border: 0;
  padding: 0 16px 16px;
}
.check-title {
  border: none;
  padding: 30px 0;
  margin-bottom: 0;
}
.platform-price {
  margin: 20px -15px;
  border-top: 1px solid #e5e5e5;
  padding: 20px 15px 0;
  font-size: 20px;
}

.shipping-list-more {
  margin: 15px 0 25px;
  text-align: center;
  color: @sui_color_link;
  cursor: pointer;
}

.shipping-options-fold {
  :deep(.shipping-item) {
    padding: 10px 8px;

    &.shipping-selected {
      border: 1px solid @sui_color_main;
    }

    .option-r {
      vertical-align: top;
    }

    .ship-type-list {
      padding-top: 0;
    }
  }
  :deep(li.is-disabled) {
    .option-des {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  :deep(.shipping-list-more) {
    margin: 24px 0;
  }
}

.shipping-options-wrap {
  background: #fff;
  &.is-single {
    padding-top: 16px;
    margin-top: 10px;

    .check-title {
      padding-top: 0;
    }
  }

  .check-title {
    padding: 12px 0 8px 0;
    border: none;
    margin: 0;

    & > h4 {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .ship-prime-wrap {
    margin: 0 0 10px 0;
  }
  
  .join-prime {
    padding: 10px;
    font-size: 13px;
    line-height: 17px;

    i {
      padding-right: 4px;
    }
  }

  .prime-free {
    line-height: normal;
  }

  .prime-btn {
    display: inline-flex;
    align-content: center;
    padding: 0;
    border: none;
    font-size: 12px;
    line-height: 17px;
    color: @sui_color_link;

    &:after {
      content: '>';
      display: block;
    }
  }
  
  .prime-switch:after {
    content: '>';
  }

  .sui-radio__check {
    margin-right: 8px;
  }

  .insurance-tip {
    margin: 8px 0 0;
    line-height: normal;

    .sui-checkbox__label-text {
      font-size: 13px;
      padding-left: 4px;
    }
  }

  .platform-price {
    font-size: 18px;
    line-height: 21px;
    padding: 11px 0 0 0;
    margin: 12px 0 0 0;
  }

  [class*="sui_icon_doubt"] {
    color: @sui_color_gray_dark2
  }
}

.shipping-options-right {
  .check-title {
    padding: 16px 0;
    > h4 {
      font-size: 20px;
      font-weight: 900;
      font-family: Arial Black;
    }
  }
  .ship-prime-wrap {
    margin: 0 0 8px 0;
  }
  .insurance-tip {
    margin-top: 16px;
  }
}
</style>
