<template>
  <div class="checkout-activity-popover__tips">
    <div
      v-if="bubbleCopyWritingByAbt"
      class="activity-popover-content"
    >
      <div 
        ref="activityPopoverTipsContentRef"
        class="activity-popover-content__wrapper"
      >
        <span
          ref="activityPopoverTipsDescRef"
          class="activity-popover-content__tips-content"
        >
          <Icon
            v-if="icon.name"
            class="activity-popover-content__tips-icon"
            :name="icon.name"
            :color="icon.color"
            :size="icon.size"
          />
          <span
            ref="descriptionRef"
            class="activity-popover-content__desc-text"
            v-html="description"
          ></span>
        </span>
        <div
          v-if="timeTips"
          ref="timedownRef"
          class="activity-popover-content__tips-timedown"
          v-html="timeTips"
        >
        </div>
      </div>
    </div>
    <div
      v-else
      class="activity-popover-content"
    >
      <div 
        ref="activityPopoverTipsContentRef"
        class="activity-popover-content__wrapper"
        :class="[!!timeTips ? 'two-rows-ellipsis' : 'three-rows-ellipsis']"
      >
        <Icon
          v-if="icon.name"
          class="activity-popover-content__tips-icon"
          :name="icon.name"
          :color="icon.color"
          :size="icon.size"
        />
        <span
          ref="descriptionRef"
          class="activity-popover-content__desc-text"
          v-html="description"
        ></span>
      </div>
      <div
        v-if="timeTips"
        class="activity-popover-content__timewdown"
      >
        {{ timeTips }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

import { template, CountDown } from '@shein/common-function'

export default {
  name: 'ActivityTips',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    },
    timedown: {
      type: [Number, String],
      default: 0
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    // 倒计时文案
    timeTips () {
      if (!this.timer) return ''
      const { H, M, S } = this.timer?.timeObj || {}
      if (this.bubbleCopyWritingByAbt) {
        return `<span class="time">${H}</span> : <span class="time">${M}</span> : <span class="time">${S}</span>`
      }
      return this.template(
        `${H}:${M}:${S}`,
        this.language.SHEIN_KEY_PC_29116
      )
    },
    isClient() {
      return typeof window !== 'undefined'
    }
  },
  watch: {
    timedown: {
      handler(newVal, oldVal) {
        this.initTimer()
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      },
      immediate: true
    },
    description: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      }
    }
  },
  methods: {
    template,
    initTimer () {
      let endTime = +this.timedown
      if (!endTime) return
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    /**
     * @description: 用于处理descript的字号
     * @return {*}
     */    
    handleDescriptionFontSize() {
      if (!this.isClient) {
        return
      }
    
      this.$nextTick(() => {
        setTimeout(()=>{
          const descriptionRef = this.$refs?.descriptionRef
          const activityPopoverTipsContentRef = this.$refs.activityPopoverTipsContentRef

          if (!descriptionRef || !activityPopoverTipsContentRef) {
            console.warn('Refs are not available yet')
            return
          }

          const descriptionHeight = this.$refs.activityPopoverTipsContentRef?.offsetHeight || 0
          const maxHeight = this.bubbleCopyWritingByAbt ? 48 : 27

          if(descriptionHeight > maxHeight) {
            this.$refs.descriptionRef.style.fontSize = '10px'
            this.$refs.activityPopoverTipsDescRef?.classList?.add('three-rows-ellipsis')
            
            if (this.bubbleCopyWritingByAbt && this.$refs?.timedownRef) {
              this.$refs.timedownRef.style.display = 'block'
            }
          } else {
            this.$refs.descriptionRef.style.fontSize = '12px'
          }
        }, 0)
      })
    }
  }
}
</script>

<style lang="less">
.checkout-activity-popover__tips {
  .margin-l(8px);
}
.activity-popover-content {
  line-height: 1;
  &__wrapper {
    line-height: 1.2;
  }
  &__tips-icon {
    .margin-r(2px);
    margin-top: -2px;
  }
  &__desc-text {
    color: @sui_color_white;
    margin-right: 6px;
    em {
      font-weight: 700;
      color: @sui_color_discount;
    }
    .prime {
      color: @sui_color_club_rosegold_dark3;
    }
    .flash {
      color: @sui_color_flash;
    }
    .prime-club {
      font-weight: 700;
      color: #C96E3F;
    }
  }
  &__tips-timedown {
    display: inline-block;
    color: var(---sui_color_discount, #FA6338);
    .time {
      display: inline-block;
      width: 14px;
      height: 14px;
      padding: 1px;
      text-align: center;
      border-radius: 1px;
      background: #FA6338;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: var(---sui_color_white, #FFF);
    }
  }
  &__timewdown {
    font-size: 10px;
    color: @sui_color_guide;
    margin-top: 2px;
  }
}
.two-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.three-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
